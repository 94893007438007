import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Icons
import { calendarClearOutline, timeOutline, peopleOutline, locationOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

// Styles
import '../../styles/tourVoucher.css';

function TourVoucher() {
  const { id } = useParams();
  const [voucherData, setVoucherData] = useState([]);
  const [loadingVoucher, setLoadingVoucher] = useState([]);

  useEffect(() => {
    setLoadingVoucher(true);
    axios.get(`https://api.mylondoncorner.com/tour/voucher/${id}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      })
      .then((response) => {
        setVoucherData(response.data);
        setLoadingVoucher(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoadingVoucher(false);
      });
  }, [id]);

  return (
    <>
    {loadingVoucher ? (
      <div className="loading-animation"></div>
    ) : (
      <div className="booking-conf-container">
        <div className="row">
          <div className="booking-conf-row-1-column-left">
            <a href={`/`}><img src="https://api.mylondoncorner.com/images/logo-black.png" /></a>
          </div>
          <div className="booking-conf-row-1-column-middle">
            <p><b>{voucherData.tourBooking._id}</b></p>
            <br />
            <h3><b>{voucherData.tourBooking.tour.name}</b></h3>
            <br />
            <p>
              <IonIcon icon={calendarClearOutline} /> {formatDateItalianWithMonthYear(voucherData.tourBooking.date) + ' ' + (voucherData.tourBooking.tour.meeting_time != 0 ? voucherData.tourBooking.tour.meeting_time : voucherData.tourBooking.privateMeetingTime) }
            </p>
            <p>
              <IonIcon icon={timeOutline} /> 
              {voucherData.tourBooking.tour.duration != 0 ? 
                ' ' + voucherData.tourBooking.tour.duration :
                ' ' + voucherData.tourBooking.duration + ' ore'
              }
            </p>
            <p><IonIcon icon={peopleOutline} /> {voucherData.tourBooking.quantity_adult} adulti, {voucherData.tourBooking.quantity_child} bambini</p>
            <br /><br />
            <p style={{ color: 'rgb(189, 189, 189)', marginBottom: '1%' }}>Prenotato da</p>
            <p>{voucherData.tourBooking.user ? voucherData.tourBooking.user.email : voucherData.tourBooking.guestEmail ? voucherData.tourBooking.guestEmail : voucherData.tourBooking.externalEmail}</p>
          </div>
          <div className="booking-conf-row-1-column-right">
            <img src="https://api.mylondoncorner.com/images/website-qrcode.png" />
          </div>
        </div>
        <br /><br />
        <hr />
        <br /><br />
        <h3>Luogo di incontro</h3>
        <br />
        <p><IonIcon icon={locationOutline} /> <b>{voucherData.tourBooking.tour.meeting_point != 'N/A' ? voucherData.tourBooking.tour.meeting_point : voucherData.tourBooking.privateMeetingPoint}</b></p>
        <br />
        <iframe
          src={
            voucherData.tourBooking.privateEmbedAMap 
              ? voucherData.tourBooking.privateEmbedAMap 
              : voucherData.tourBooking.tour.embed_a_map
          }
          width="100%"
          height="260"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
        <br /><br /><br />
        <hr />
        <br /><br />
        <div className="row">
          <div className="booking-conf-row-3-column-left">
            <h3>Cosa portare</h3>
            <br />
            {voucherData.tourBooking.tour.what_to_bring.split("•").map((item, index) => {
              if (index !== 0) {
                return (
                  <p key={index}>• {item}</p>
                );
              }
              return null;
            })}
          </div>
          <div className="booking-conf-row-3-column-right">
            <h3>Bisogno di aiuto?</h3>
            <br />
            <p>Per informazioni riguardanti il luogo di incontro contattaci.</p>
            <br />
            <p><b>Nome</b>: <span>Mylondoncorner</span></p>
            <p><b>Telefono</b>: <span>+44 7853545887</span></p>
            <p><b>E-mail</b>: <span>info@mylondoncorner.com</span></p>
            <br />
          </div>
        </div>
        <br /><br />
        <p style={{ fontSize: '10px', textAlign: 'center' }}>Hai confermato di accettare i Termini e condizioni di utilizzo di Mylondoncorner (mylondoncorner.com/termini-e-condizioni) e l'Informativa sulla privacy (mylondoncorner.com/privacy-policy). mylondoncorner.com, Tour Operator, Attrazioni e Attività nel Regno Unito. Mylondoncorner Ltd, Suite 205 Balfour House, 741 High Road, London, United Kingdom, N12 0BP. Registrazione compagnia: 14771046</p>
      </div>
      )}
    </>
  )
}

const formatDateItalianWithMonthYear = (dateString) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

export default TourVoucher;