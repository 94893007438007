import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { cart } from 'ionicons/icons';
import '../../styles/widgets/CartCircularButton.css';

const CircularButton = () => {
  const navigate = useNavigate();

  return (
    <button className="cart-circular-button" onClick={() => navigate(`/basket`)}>
      <IonIcon icon={cart} className="cart-circular-button-cart-icon" />
    </button>
  );
};

export default CircularButton;