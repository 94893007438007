import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getPerformance } from 'firebase/performance';
import { getAnalytics } from 'firebase/analytics';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAAUMC1sDsJsD6f1BAtkq1n64Lq2r5j4Lg",
  authDomain: "mylondoncorner.firebaseapp.com",
  projectId: "mylondoncorner",
  storageBucket: "mylondoncorner.appspot.com",
  messagingSenderId: "71304875747",
  appId: "1:71304875747:web:ff775b90a8c3e399acffd6"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const GoogleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);

export const storage = getStorage(app);

export const perf = getPerformance(app); // DONE
export const analytics = getAnalytics(app); // DONE