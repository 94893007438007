import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Icons
import { trashOutline, lockClosedOutline, cartOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

// Context
import { useAuth } from '../components/AuthContext';

// Components
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';
import FeedbackPopup from '../components/widgets/FeedbackPopup';

// Styles
import '../styles/basket.css';

function Basket() {
  // Using AuthContext to maintain user authentication state across multiple components
  const { user } = useAuth();
  const navigate = useNavigate();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [basketData, setBasketData] = useState([]);
  const [loadingBasket, setLoadingBasket] = useState(true);

  const [guestEmail, setGuestEmail] = useState();

  const [totalPrice, setTotalPrice] = useState();

  useEffect(() => {
    let timeoutId;
  
    const fetchData = () => {
      if (user && user.uid) {
        setLoadingBasket(true);
        axios.get(`https://api.mylondoncorner.com/basket/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
          }
        })
        .then((response) => {
          setBasketData(response.data);
          setLoadingBasket(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoadingBasket(false);
        });
      }
    };
  
    const refreshData = () => {
      timeoutId = setTimeout(() => {
        if (basketData.length === 0) {
          fetchData();
        }
      }, 1500); // Refresh after 1.5 second
    };
  
    fetchData(); // Initial fetch
    refreshData(); // Start the refresh loop
  
    return () => {
      clearTimeout(timeoutId); // Cleanup
    };
  }, [user, basketData.length]); // Dependency array includes basketData.length

  useEffect(() => {
    if(basketData.tourBasketItems) {
      setTotalPrice(basketData.tourBasketItems.reduce((total, item) => total + (item.quantity_adult * item.tour.price) + (item.quantity_child * item.tour.price_child), 0));
      setLoadingBasket(false);
    }
  }, [basketData]);

  // Handler function to remove an item from the basket
  const handleRemoveItem = (itemId) => {
    axios.get(`https://api.mylondoncorner.com/basket/remove/${itemId}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      })
      .then((response) => {
        // Refresh basket data after item removal
        // You may want to implement a more efficient way to update the basket data, like removing the item directly from the state
        axios.get(`https://api.mylondoncorner.com/basket/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
            }
          })
          .then((response) => {
            setBasketData(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      })
      .catch((error) => {
        console.error('Error removing item:', error);
      });
  };

  const handleCheckout = () => {
    if(user.isAnonymous && !guestEmail) {
      setFeedbackMessage("Inserisci la tua e-mail");
      setIsPopupOpen(true);
    } else {
      axios.get(`https://api.mylondoncorner.com/basket/checkout/${user.uid}/${guestEmail}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        const checkoutUrl = response.data.url;
        window.location.href = checkoutUrl;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    }
  };

  return (
    <>
      <Navbar />
        <>
          <br />
          <div className="home-container">
            {loadingBasket ? (
              <>
              <br /><br /><br />
              <h3 style={{ textAlign: 'center' }}>Il tuo carrello è vuoto al momento!</h3>
              <br />
              <IonIcon icon={cartOutline} style={{ fontSize: '200px' }} />
              <br /><br />
              <button className='button' onClick={() => navigate('/tour-di-londra')}>Scopri i nostri tour</button>
              <br /><br />
            </>
            ) : (
              <div>
                {loadingBasket ? (
                  <div className="loading-animation"></div>
                ) : (
                  <div>
                    {basketData.tourBasketItems && basketData.tourBasketItems.length > 0 ? (
                      <>
                        <br />
                        <h1 style={{ textAlign: 'left' }}>
                          Carrello
                        </h1>
                        <br />
                        <p style={{ textAlign: 'left' }}>
                          Prenditi un momento per controllare i dettagli qui sotto prima di procedere al pagamento.
                        </p>
                        <br /><br />
                        <div className='row'>
                          <div className='basket-left-column'>
                            {basketData.tourBasketItems.map((item, index) => (
                              <>
                                <div className='basket-item' key={index}>
                                  <div className='basket-item-title'>
                                    <img src={process.env.REACT_APP_API_URL + '/' + item.tour.images_paths[0]} />
                                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                      {item.tour.name}
                                      <p style={{ fontSize: '16px', marginTop: '1%' }}>🕐 {formatDate(item.date)} • {item.tour.meeting_time}</p>
                                    </p>
                                  </div>
                                  <br />
                                  <p style={{ marginTop: '1%' }}>🎫 Lingua: Italiano</p>
                                  <p style={{ marginTop: '1%' }}>
                                    👥 {item.quantity_adult} {item.quantity_adult === 1 ? 'adulto' : 'adulti'}, {item.quantity_child} {item.quantity_child === 1 ? 'bambino' : 'bambini'}
                                  </p>
                                  <br />
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <button className='basket-delete-button' onClick={() => handleRemoveItem(item._id)}><IonIcon icon={trashOutline} /></button>
                                    <p style={{ flex: '1', textAlign: 'right', marginLeft: '10px' }}>
                                      £{(item.tour.price * item.quantity_adult) + (item.tour.price_child * item.quantity_child)}
                                    </p>
                                  </div>
                                </div>
                                <br />
                              </>
                            ))}
                            {user && user.isAnonymous ? (
                                <div className='basket-only-small-screen'>
                                  <input type='text' id="email" name="email" onChange={(e) => setGuestEmail(e.target.value)} style={{ padding: '10px', borderRadius: '10px', fontSize: '15px' }} placeholder='Inserisci la tua e-mail' />
                                  <br /><br />
                                </div>
                              ) : null}
                              <br />
                            <button className='button' onClick={handleCheckout}>Checkout <IonIcon icon={lockClosedOutline} /></button>
                          </div>
                          <div className='basket-right-column'>
                            <div className='basket-checkout-box'>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h3>Totale ({basketData.tourBasketItems.length} prodotti):</h3>
                                <h3>£{totalPrice}</h3>
                              </div>
                              <br />
                              <p>Tutte le tasse e le spese incluse</p>
                              <br />
                              {user && user.isAnonymous ? (
                                <>
                                  <input type='text' id="email" name="email" onChange={(e) => setGuestEmail(e.target.value)} style={{ padding: '10px', borderRadius: '10px', fontSize: '15px' }} placeholder='Inserisci la tua e-mail' />
                                  <br /><br />
                                </>
                              ) : null}
                              <button className='button' onClick={handleCheckout}>Checkout <IonIcon icon={lockClosedOutline} /></button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <br /><br /><br />
                        <h3 style={{ textAlign: 'center' }}>Il tuo carrello è vuoto al momento!</h3>
                        <br />
                        <IonIcon icon={cartOutline} style={{ fontSize: '200px' }} />
                        <br /><br />
                        <button className='button' onClick={() => navigate('/tour-di-londra')}>Scopri i nostri tour</button>
                        <br /><br />
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      <Footer />
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={() => setIsPopupOpen(false)}
        confirmButtonText="OK"
      />
    </>
  );
}

// Define a function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export default Basket;
