import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

import '../../../styles/admin/adminElse.css';

function Send() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [subject, setSubject] = useState(null);
  const [htmlEmail, setHtmlEmail] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  const handleSubmission = async () => {
    if(subject !== null && htmlEmail !== null) {
      axios.post('https://api.mylondoncorner.com/newsletter/send', {
        subject: subject,
        htmlEmail: htmlEmail
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          alert('Emails sent')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      alert("Every field must be typed in!");
    }
  };

  return (
    <>
      {loadingUserData ? (
        <div className="loading-animation"></div>
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

                <br /><br />

                <div className='admin-container'>

                  <h1>Send Newsletter</h1>

                  <br /><br />

                  <p>Subject</p>
                  <input type="text" id="subject" name="subject" onChange={(e) => setSubject(e.target.value)} placeholder='Subject' />

                  <br /><br />

                  <p>Html Email</p>
                  <textarea id="todo" rows={25} name="todo" onChange={(e) => setHtmlEmail(e.target.value)} placeholder='Html Email' />

                  <br /><br /><br />

                  <button className='button' onClick={handleSubmission}>Send</button>

                </div>

              <Footer />
            </>
          ) : (
            navigate('/')
          )
        ) : (
          navigate('/')
        )
      )}
    </>
  );
}

export default Send;