import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { checkmarkCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';

function PaymentSuccessful() {
  const { ids } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios.post('https://api.mylondoncorner.com/basket/checkout/payment-successful', {
        ids: ids.split(',')
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        console.error(response)
        if(response.data.error) {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <>
      <Navbar />
      <br /><br /><br />
      <IonIcon icon={checkmarkCircleOutline} style={{ display: 'block', margin: '0 auto', color: 'green', fontSize: '50px' }} />
      <br />
      <h2 style={{ textAlign: 'center' }}>Pagamento andato a buon fine</h2>
      <br /><br />
      <button className='button' onClick={() => navigate('/account')}>Visualizza le tue prenotazioni</button>
      <Footer />
    </>
  );
}

export default PaymentSuccessful;