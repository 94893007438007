import React, { useState, useEffect } from 'react';
import '../styles/hpCounter.css';

const HouseCounter = ({ house, imageSrc }) => {
    const [points, setPoints] = useState(0);

    useEffect(() => {
        const storedPoints = localStorage.getItem(house);
        if (storedPoints !== null) {
            setPoints(parseInt(storedPoints));
        }
    }, [house]);

    const handlePlus = () => {
        const newPoints = points + 5;
        setPoints(newPoints);
        localStorage.setItem(house, newPoints.toString());
    };

    const handleMinus = () => {
        const newPoints = points - 5;
        setPoints(newPoints);
        localStorage.setItem(house, newPoints.toString());
    };

    return (
        <div className="hp-counter-card">
            <img src={imageSrc} alt={house} className="hp-counter-image" />
            <h1 className="hp-counter-title">{house}</h1>
            <div className="hp-counter-controls">
                <button onClick={handlePlus} aria-label={`Add points to ${house}`}>
                    <span className="hp-counter-button">+</span>
                </button>
                <p className="hp-counter-points">{points}</p>
                <button onClick={handleMinus} aria-label={`Subtract points from ${house}`}>
                    <span className="hp-counter-button">−</span>
                </button>
            </div>
        </div>
    );
};

const HpCounter = () => {
    useEffect(() => {
        document.body.style.backgroundColor = 'black';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    return (
        <div className="hp-counter-container">
            <HouseCounter house="Grifondoro" imageSrc={`${process.env.REACT_APP_BASE_URL}/images/hp-counter/grifondoro.png`} />
            <HouseCounter house="Serpeverde" imageSrc={`${process.env.REACT_APP_BASE_URL}/images/hp-counter/serpeverde.png`} />
            <HouseCounter house="Corvonero" imageSrc={`${process.env.REACT_APP_BASE_URL}/images/hp-counter/corvonero.png`} />
            <HouseCounter house="Tassorosso" imageSrc={`${process.env.REACT_APP_BASE_URL}/images/hp-counter/tassorosso.png`} />
        </div>
    );
};

export default HpCounter;
