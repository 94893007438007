import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Navigation
import { useNavigate } from 'react-router-dom';

// Components
import HomeNavbar from '../../components/navbar/HomeNavbar';
import Footer from '../../components/Footer';

// Styles
import '../../styles/trip/trips.css';

function Trips() {
  const navigate = useNavigate();

  const [tripsData, setTripsData] = useState([]);
  const [loadingTrips, setLoadingTrips] = useState(true);

  useEffect(() => {
    setLoadingTrips(true);
    axios.get(`https://api.mylondoncorner.com/trips/`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setTripsData(response.data);
      setLoadingTrips(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTrips(false);
    });
  }, []);

  return (
    <>
      <HomeNavbar title='Viaggi di gruppo in italiano' description="Scegli l'itinerario che fa per te!" img='https://api.mylondoncorner.com/images/featured-banner-trips.jpg' />
      <br /><br /><br /><br />
      <div className='trips-container'>
        <br />
        <h1 className='home-shorter-title'>Viaggi di gruppo in partenza</h1>
        <br />
        <h3 style={{ textAlign: 'center', color: '#2e2e2e' }}>Scegli l'itinerario che fa per te!</h3>
        <br /><br /><br />
        {loadingTrips ? (
          <div className="loading-animation"></div>
        ) : (
          <>
            <h2 style={{ color: '#00247D' }}>VIAGGI IN PARTENZA</h2>
            <br />
            {tripsData.trips.filter(item => item.visible === true && new Date() < new Date(item.departure_date)).map((trip) => (
              <div className='trips-card' onClick={() => navigate('/trip/' + trip.slug)}>
                <p>{formatDateItalian(trip.departure_date) + '-' + formatDateItalianWithMonth(trip.return_date)} • {calculateNights(trip.departure_date, trip.return_date)} notti</p>
                <h2>{trip.name_short}</h2><br />
                <p>{'Da ' + trip.price + '€ / persona'}</p>
                <p>{trip.status === 'sold out' ? 'SOLD OUT' : 'APERTO' }</p>
                <button className='button'>Scopri il viaggio</button>
              </div>
            ))}
            <br /><br />
            <hr />
            <br /><br />
            <h2 style={{ color: '#00247D' }}>VIAGGI PRECEDENTI</h2>
            <br />
            {tripsData.trips.filter(item => item.visible === true && new Date() > new Date(item.departure_date)).map((trip) => (
              <div className='trips-card' onClick={() => navigate('/trip/' + trip.slug)}>
                <p>{formatDateItalian(trip.departure_date) + '-' + formatDateItalianWithMonth(trip.return_date)} • {calculateNights(trip.departure_date, trip.return_date)} notti</p>
                <h2>{trip.name}</h2>
                <p>{'Da ' + trip.price + '€ / persona'}</p>
                <p>{trip.status}</p>
                <button className='button'>Scopri il viaggio</button>
              </div>
            ))}
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

const formatDateItalianWithMonth = (dateString) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

const formatDateItalian = (dateString) => {
  const options = { day: 'numeric' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

function calculateNights(departure_date, return_date) {
  const nights = [];
  let currentDate = new Date(departure_date);

  while (currentDate <= new Date(return_date)) {
    nights.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return nights.length - 1;
}

export default Trips;