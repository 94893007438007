import React from 'react';
import { FaEnvelope, FaLock } from "react-icons/fa";

function InputText({ type, label, name, id, width, onChange }) {

  const style = {
    width: width,
    padding: '2%',
    borderRadius: '10px',
    fontSize: '15px',
    position: 'relative'
  }

  const logoStyle = {
    position: 'absolute',
    top: '50%', // Adjust as needed
    right: '10px', // Adjust as needed
    transform: 'translateY(-50%)',
    // You can add more styles for the logo here
  }

  return (
    <>
      <label style={{ position: 'relative' }}>
        <input
          type={type}
          id={id}
          name={name}
          style={style}
          placeholder={label}
          onChange={onChange} // Pass the onChange prop to the input element
        />
        {label === 'E-mail' || label === 'Conferma E-mail' ? <FaEnvelope style={logoStyle} /> : null}
        {(label === 'Password' || label === 'Conferma Password') && <FaLock style={logoStyle} />}
      </label>
    </>
  );
}

export default InputText;
