import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/components/ServiceCard.css';

export default function ServiceCard({ img, url, title, description, bgcolor }) {
  const navigate = useNavigate();

  // Inline style for dynamic background color
  const cardStyle = {
    backgroundColor: bgcolor || '#ff6370', // Default color if no bgcolor is provided
  };

  return (
    <div
      className='service-card'
      style={cardStyle}
      onClick={(e) => { e.preventDefault(); navigate(url); }}
    >
      <img src={img} alt="Image" />
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
}
