import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

import '../../../styles/admin/adminElse.css';

function NewTodo() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [todoDate, setTodoDate] = useState(null);
  const [todo, setTodo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    console.error(todoDate)
  }, [todoDate])

  const handleTodoSubmission = async () => {
    if(todo !== null && todoDate !== null) {
      axios.post('https://api.mylondoncorner.com/todo/save', {
        user: userData.user._id,
        todoDate: todoDate,
        todo: todo
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/admin/todos')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      alert("Every field must be typed in!");
    }
  };

  return (
    <>
      {loadingUserData ? (
        <div className="loading-animation"></div>
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

                <br /><br />

                <div className='admin-container'>

                  <h1>New Todo</h1>

                  <br /><br />

                  <p>Todo Date</p>
                  <input type='date' id="todoDate" name="todoDate" onChange={(e) => setTodoDate(e.target.value)} />

                  <br /><br />

                  <p>Todo</p>
                  <textarea id="todo" rows={12} name="todo" onChange={(e) => setTodo(e.target.value)} placeholder='Todo' />

                  <br /><br /><br />

                  <button className='button' onClick={handleTodoSubmission}>Submit</button>

                </div>

              <Footer />
            </>
          ) : (
            navigate('/')
          )
        ) : (
          navigate('/')
        )
      )}
    </>
  );
}

export default NewTodo;