import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Firebase
import { db, auth, GoogleProvider } from '../../config/firebase';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { collection, setDoc, getDoc, doc } from 'firebase/firestore';

// Components
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';
import InputText from '../../components/inputs/InputText';
import Message from '../../components/Message';

// Styles
import '../../styles/signup.css';

function Signup() {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postcode, setPostcode] = useState("");

  const [message, setMessage] = useState("");

  const usersCollectionRef = collection(db, "users");

  const signup = async () => {
    try {
      let firebaseUserId;

      // POST user to firestore 
      await createUserWithEmailAndPassword(auth, email, password).then(cred => {
        firebaseUserId = cred.user.uid;
        return setDoc(doc(usersCollectionRef, cred.user.uid), {
          email: email,
          registrationDate: new Date()
        });
      });

      // POST user to MongoDB
      axios.post('https://api.mylondoncorner.com/user/create', {
        firebaseUserId: firebaseUserId,
        email: email,
        name: name,
        surname: surname,
        address: address,
        city: city,
        country: country,
        postcode: postcode
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      })
      .then(response => {
        console.log(response.data); // Handle response data here
      })
      .catch(error => {
        console.error('Error:', error); // Handle errors here
      });

      navigate('/');
    } catch (err) {
      if (err.code === 'auth/invalid-email') {
        setMessage('Invalid email.');
      } else if (err.code === 'auth/missing-password') {
        setMessage('Missing password.');
      } else if (err.code === 'auth/weak-password') {
        setMessage('Weak password, should be at least 6 characters.');
      } else if (err.code === 'auth/email-already-in-use') {
        setMessage('Email already in use.');
      } else {
        console.log('Error signing in:', err.message);
      }
    };
  };

  const loginWithGoogle = async () => {
    try {
      const cred = await signInWithPopup(auth, GoogleProvider);
      // Check if the user already exists in the database
      const userDoc = doc(usersCollectionRef, cred.user.uid);
      const docSnap = await getDoc(userDoc);
      if (!docSnap.exists()) {
        // If not, add the user to firestore db
        await setDoc(userDoc, {
          email: cred.user.email,
          registrationDate: new Date()
        });

        // POST user to MongoDB
        axios.post('https://api.mylondoncorner.com/user/create', {
          firebaseUserId: cred.user.uid,
          email: cred.user.email,
          name: cred.user.displayName.split(' ')[0],
          surname: cred.user.displayName.split(' ').slice(1).join(' ')
        }, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
          }
        });
      }
      navigate('/');
    } catch (err) {
      setMessage(err);
    }
  };

  return (
    <>
      <Navbar />
      <div className="row">
        <br></br>
        <div class="signup-box">
          <h1>Registrati</h1>
          <br />
          {message ? (
            <>
              <br />
              <Message type={'error'} message={message} /> 
              <br />
            </>
          ) : null}
          <br />
          <button className='button' style={{ textAlign: 'center', backgroundColor: 'white', color: 'black', border: '1px solid black' }} onClick={loginWithGoogle}>
            <img className='signup-social-logo' src='https://static.vecteezy.com/system/resources/previews/012/871/371/non_2x/google-search-icon-google-product-illustration-free-png.png' alt='Google Icon' style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '10px' }} />
            <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Registrati con Google</span>
          </button>
          <br />
          <InputText type='email' label='E-mail*' id='email' name='email' required='true' width='100%' onChange={(e) => setEmail(e.target.value)} />
          <br /><br />
          <InputText type='password' label='Password*' id='password' name='password' required='true' width='100%' onChange={(e) => setPassword(e.target.value)} />     
          <br /><br />
          <InputText type='password' label='Conferma Password*' id='confirmpassword' name='confirmpassword' required='true' width='100%' onChange={(e) => setPassword(e.target.value)} />     
          <br /><br />
          <InputText type='text' label='Nome*' id='name' name='name' required='true' width='100%' onChange={(e) => setName(e.target.value)} />
          <br /><br />
          <InputText type='text' label='Cognome*' id='surname' name='surname' required='true' width='100%' onChange={(e) => setSurname(e.target.value)} />
          <br /><br />
          <InputText type='text' label='Indirizzo*' id='address' name='address' required='true' width='100%' onChange={(e) => setAddress(e.target.value)} />
          <br /><br />
          <InputText type='text' label='Citta*' id='city' name='city' required='true' width='100%' onChange={(e) => setCity(e.target.value)} />
          <br /><br />
          <InputText type='text' label='Paese*' id='country' name='country' required='true' width='100%' onChange={(e) => setCountry(e.target.value)} />
          <br /><br />
          <InputText type='text' label='Codice postale*' id='postcode' name='postcode' required='true' width='100%' onChange={(e) => setPostcode(e.target.value)} />
          <br /><br /><br />
          <div className="consent-container">
            <label className="custom-checkbox">
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
            <label htmlFor="consent-checkbox" className="consent-text">
              Ho letto e accetto la <a href="/legal/privacy-policy">privacy policy</a>.
            </label>
          </div>
          <br /><br />
          <button className='button' onClick={signup}>Registrati</button>
          <br />
          <hr />
          <br /> 
          <p>Hai gia' un account?</p>
          <br />
          <button className='button' onClick={() => navigate('/login')}>Login</button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;