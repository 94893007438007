import React from 'react';

import '../../styles/widgets/stickyBottomBar.css';

const StickyBottomBar = ({ dates, price, buttonText, onButtonClick }) => {
  return (
    <div className="sticky-bottom-bar">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <p><b>{price}</b> / persona</p>
          <p><b>{dates}</b></p>
        </div>
      </div>
      <button className="sticky-bottom-button" onClick={onButtonClick}>
        {buttonText}
      </button>
    </div>
  );
};

export default StickyBottomBar;
