import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';

import '../styles/tour/tourRequested.css'

function PaymentSuccessful() {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <img src='https://api.mylondoncorner.com/images/owl-with-letter.png' className='tour-requested-img' />
      <h2 className='tour-requested-h2'>Gufo spedito! Abbiamo ricevuto la tua richiesta!</h2>
      <br />
      <p className='tour-requested-p'>Riceverai risposta sulla e-mail da te inserita durante la compilazione del modulo!</p>
      <Footer />
    </>
  );
}

export default PaymentSuccessful;