import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// Icons
import { airplane, call, card } from 'ionicons/icons'; // Importing icons from Ionicons
import { IonIcon } from '@ionic/react'; // Importing IonIcon component from @ionic/react

// Navigation
import { useNavigate } from 'react-router-dom';

// Components
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';
import FeedbackPopup from '../components/widgets/FeedbackPopup';

// Styles
import '../styles/otherProducts.css';
import '../styles/tables.css';
import '../styles/inputs.css';

function Taxi() {
  const navigate = useNavigate();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [passengers, setPassengers] = useState(1);
  const [pickupDate, setPickupDate] = useState('');
  const [pickupTime, setPickupTime] = useState('12:00');
  const [flightNumber, setFlightNumber] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [startingPoint, setStartingPoint] = useState('');
  const [destination, setDestination] = useState('');
  const [startingLocations, setStartingLocations] = useState([]);
  const [destinationLocations, setDestinationLocations] = useState([]);

  const [isReturn, setIsReturn] = useState(false); // State for return trip
  const [returnPickupDate, setReturnPickupDate] = useState('');
  const [returnPickupTime, setReturnPickupTime] = useState('12:00');
  const [returnFlightNumber, setReturnFlightNumber] = useState('');

  const debounceTime = 300;

  useEffect(() => {
    // Calculate the default pickup date (2 days from now)
    const now = new Date();
    // Andata
    now.setDate(now.getDate() + 2);
    var formattedDate = now.toISOString().split('T')[0]; // Format date to YYYY-MM-DD
    setPickupDate(formattedDate); // Set default pickup date
    // Ritorno
    now.setDate(now.getDate() + 3);
    formattedDate = now.toISOString().split('T')[0]; // Format date to YYYY-MM-DD
    setReturnPickupDate(formattedDate); // Set default pickup date
  }, []); // Empty dependency array to run only on mount

  useEffect(() => {
    if (!startingPoint) {
      setStartingLocations([]);
      return;
    }
    const handler = setTimeout(() => {
      const fetchStartingLocations = async () => {
        try {
          const response = await axios.get(`https://nominatim.openstreetmap.org/search?addressdetails=1&q=${startingPoint}, england&countrycodes=gb&format=json&accept-language=it`);
          if (response.data.length) {
            const locationData = response.data.map(location => ({
              display_name: location.display_name,
              name: location.name,
              road: location.address.road,
              city: location.address.city,
              country: location.address.country,
              lat: location.lat,
              lon: location.lon
            }));
            setStartingLocations(locationData);
          } else {
            console.log('No starting locations found.');
          }
        } catch (error) {
          console.error('Error fetching starting locations:', error);
        }
      };
      fetchStartingLocations();
    }, debounceTime);
    return () => clearTimeout(handler);
  }, [startingPoint]);

  useEffect(() => {
    if (!destination) {
      setDestinationLocations([]);
      return;
    }
    const handler = setTimeout(() => {
      const fetchDestinationLocations = async () => {
        try {
          const response = await axios.get(`https://nominatim.openstreetmap.org/search?addressdetails=1&q=${destination}, england&countrycodes=gb&format=json&accept-language=it`);
          if (response.data.length) {
            const locationData = response.data.map(location => ({
              display_name: location.display_name,
              name: location.name,
              road: location.address.road,
              city: location.address.city,
              country: location.address.country,
              lat: location.lat,
              lon: location.lon
            }));
            setDestinationLocations(locationData);
          } else {
            console.log('No destination locations found.');
          }
        } catch (error) {
          console.error('Error fetching destination locations:', error);
        }
      };
      fetchDestinationLocations();
    }, debounceTime);
    return () => clearTimeout(handler);
  }, [destination]);

  const handleSelectLocation = (type, location) => {
    if (type === 'starting') {
      setStartingPoint(location);
      setStartingLocations([]);
    } else {
      setDestination(location);
      setDestinationLocations([]);
    }
  };

  const handleItinerarySubmission = async () => {
    if (name && email && phoneNumber && startingPoint && destination && passengers && pickupDate && pickupTime && email.includes('@')) {
      let message = `
        <div>
          <h2>One Way:</h2>
          <ul>
            <li><strong>Phone Number:</strong> ${phoneNumber}</li>
            <li><strong>Starting Point:</strong> ${startingPoint}</li>
            <li><strong>Destination:</strong> ${destination}</li>
            <li><strong>Passengers:</strong> ${passengers}</li>
            <li><strong>Pick-up Date:</strong> ${pickupDate}</li>
            <li><strong>Pick-up Time:</strong> ${pickupTime}</li>
            <li><strong>Flight Number:</strong> ${flightNumber}</li>
          </ul>
        </div>
      `;
      if (isReturn) {
        message += `
          <div>
            <h2>Return:</h2>
            <ul>
              <li><strong>Return Starting Point:</strong> ${destination}</li>
              <li><strong>Return Destination:</strong> ${startingPoint}</li>
              <li><strong>Return Date:</strong> ${returnPickupDate}</li>
              <li><strong>Return Time:</strong> ${returnPickupTime}</li>
              <li><strong>Return Flight Number:</strong> ${returnFlightNumber}</li>
            </ul>
          </div>
        `;
      }

      axios.post('https://api.mylondoncorner.com/contact', {
        subject: 'Richiesta Trasferimento Taxi',
        name,
        email,
        message
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
        .then((response) => {
          if (response.status === 200) {
            navigate('/contact/received');
          }
        })
        .catch((error) => {
          console.error('Error sending data:', error);
        });
    } else {
      setFeedbackMessage(email.includes('@') ? "Tutti i campi devono essere compilati!" : "E-mail non valida!");
      setIsPopupOpen(true);
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Taxi</title>
        <meta name="description" content="Ottieni un taxi privato base alle tue necessità" />
      </Helmet>

      <Navbar />

      <br /><br />

      <div className='post-container'>
        <h2>Prenota il tuo Trasferimento per l'aeroporto</h2>
        <br />
        <p>Trasferimenti aeroportuali facili da e per il tuo alloggio</p>
        <br /><br />

        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
          <label style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <input
              type="checkbox"
              checked={!isReturn}
              onChange={() => setIsReturn(false)}
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: '2px solid #007bff',
                appearance: 'none',
                outline: 'none',
                cursor: 'pointer',
                marginRight: '10px',
                backgroundColor: !isReturn ? '#007bff' : 'white',
              }}
            />
            Sola andata
          </label>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={isReturn}
              onChange={() => setIsReturn(true)}
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: '2px solid #007bff',
                appearance: 'none',
                outline: 'none',
                cursor: 'pointer',
                marginRight: '10px',
                backgroundColor: isReturn ? '#007bff' : 'white',
              }}
            />
            Andata e ritorno
          </label>
        </div>

        <br /><br />

        <div className='row'>
          <h3>Andata</h3>
          <br />
          <div className='other-products-column-input-3'>
            <p>Inserisci il Punto di Partenza</p>
            <input
              type='text'
              onChange={(e) => setStartingPoint(e.target.value)} // Update input value immediately
              // placeholder='Inserisci il punto di partenza'
              value={startingPoint} // Ensure the input reflects the state
            />
            <br /><br />
            {startingLocations.length > 0 && (
              <ul className="dropdown">
                {startingLocations.map((location, index) => (
                  <li key={index} onClick={() => handleSelectLocation('starting', location.display_name)}>
                    {location.name}, {location.road}<br />
                    {location.city}, {location.country}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci la Destinazione</p>
            <input
              type='text'
              onChange={(e) => setDestination(e.target.value)} // Update input value immediately
              // placeholder='Inserisci la destinazione'
              value={destination} // Ensure the input reflects the state
            />
            <br /><br />
            {destinationLocations.length > 0 && (
              <ul className="dropdown">
                {destinationLocations.map((location, index) => (
                  <li key={index} onClick={() => handleSelectLocation('destination', location.display_name)}>
                    {location.name}, {location.road}<br />
                    {location.city}, {location.country}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci Numero di Passeggeri</p>
            <input
              type='number'
              value={passengers}
              onChange={(e) => setPassengers(Number(e.target.value))}
            />
            <br /><br />
          </div>
        </div>
        <div className='row'>
          <div className='other-products-column-input-3'>
            <p>Inserisci Data del Pick-up</p>
            <input
              type='date'
              value={pickupDate}
              onChange={(e) => setPickupDate(e.target.value)}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci Orario del Pick-up</p>
            <input
              type='time'
              value={pickupTime}
              onChange={(e) => setPickupTime(e.target.value)}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci Numero di Volo</p>
            <input
              type='text'
              value={flightNumber}
              // placeholder='Inserisci Numero di Volo (Es. FR101)'
              onChange={(e) => setFlightNumber(e.target.value)}
            />
            <br /><br />
          </div>
        </div>
        <div className='row'>
          <div className='other-products-column-input-3'>
            <p>Inserisci il tuo Nome</p>
            <input
              type='text'
              value={name}
              // placeholder='Inserisci il tuo Nome'
              onChange={(e) => setName(e.target.value)}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci la tua E-mail</p>
            <input
              type='text'
              value={email}
              // placeholder='Inserisci la tua E-mail'
              onChange={(e) => setEmail(e.target.value)}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci il tuo Numero di Telefono</p>
            <input
              type='text'
              value={phoneNumber}
              // placeholder='Inserisci il tuo Numero di Telefono'
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <br /><br />
          </div>
        </div>
        <div className='row'>
          {/* Conditionally render return trip details if "return" is selected */}
          {isReturn && (
            <>
              <br />
              <h3>Ritorno</h3>
              <br />
              <div className='other-products-column-input-3'>
                <p>Punto di Partenza Ritorno</p>
                <input
                  type='text'
                  // placeholder='Punto di Partenza Ritorno'
                  value={destination}
                />
                <br /><br />
              </div>
              <div className='other-products-column-input-3'>
                <p>Destinazione del Ritorno</p>
                <input
                  type='text'
                  // placeholder='Destinazione Ritorno'
                  value={startingPoint}
                />
                <br /><br />
              </div>
              <div className='other-products-column-input-3'>
                <p>Numero di Passeggeri Ritorno</p>
                <input
                  type='number'
                  value={passengers}
                />
                <br /><br />
              </div>
              <div className='other-products-column-input-3'>
                <p>Inserisci Data del Ritorno</p>
                <input
                  type='date'
                  value={returnPickupDate}
                  onChange={(e) => setReturnPickupDate(e.target.value)}
                />
                <br /><br />
              </div>
              <div className='other-products-column-input-3'>
                <p>Inserisci Orario del Ritorno</p>
                <input
                  type='time'
                  value={returnPickupTime}
                  onChange={(e) => setReturnPickupTime(e.target.value)}
                />
                <br /><br />
              </div>
              <div className='other-products-column-input-3'>
              <p>Inserisci Numero di Volo Ritorno</p>
              <input
                type='text'
                value={returnFlightNumber}
                // placeholder='Inserisci Numero di Volo (Es. FR102)'
                onChange={(e) => setReturnFlightNumber(e.target.value)}
              />
              <br /><br />
            </div>
            </>
          )}
        </div>

        <br />

        <button className='button' onClick={handleItinerarySubmission}>Richiedi</button>

        <br /><br /><br />

        <div className='other-products-column-input-3-icons-row'>
          <div className='other-products-column-input-3-icons'>
            <IonIcon icon={airplane} />
            <div className='other-products-column-input-3-icons-text'><b>Tracking del volo</b><br />Il tuo autista monitora il tuo volo e ti aspetta in caso di ritardo.</div>
          </div>
          <div className='other-products-column-input-3-icons'>
            <IonIcon icon={card} />
            <div className='other-products-column-input-3-icons-text'><b>Prezzi fissi</b><br />Il prezzo è confermato in anticipo, nessun costo extra.</div>
          </div>
          <div className='other-products-column-input-3-icons'>
            <IonIcon icon={call} />
            <div className='other-products-column-input-3-icons-text'><b>Supporto in italiano</b><br />Riceverai supporto in italiano per qualsiasi evenienza.</div>
          </div>
        </div>

        <br /><br /><br />

        <h2>Informazioni importanti</h2>

        <br />

        <p>• In ogni macchina possono stare un massimo di 7 persone.</p><br />
        <p>• I seggiolini sono disponibili su richiesta ma non obbligatori, £12 l'uno.</p><br />
        <p>• I prezzi della tabella sono a tratta (Da aeroporto a Londra o da Londra a aeroporto).</p><br />
        <p>• I prezzi della tabella includono solamente 1 bagaglio a mano a persona.</p><br />
        <p>• Per bagagli da stiva, sedie a rotelle, sacche da golf, seggiolini etc., si applicherà un supplemento, comunicaci se ne hai bisogno all'interno del form di richiesta.</p>

        <br /><br />

        <h2>Politica di Cancellazione</h2>

        <br />

        <p>• Cancellazione gratuita fino a 48 ore prima del trasferimento.</p><br />
        <p>• Penale del 50% per cancellazioni con meno di 48 ore di preavviso.</p><br />
        <p>• Penale del 100% per cancellazioni con meno di 24 ore di preavviso.</p>

        <br /><br />

        <h2>Prezzi</h2>

        <br />

        <table>
            <tr>
              <th>Aeroporti</th>
              <th>1-2 Pax</th>
              <th>3-4 Pax</th>
              <th>5-6-7 Pax</th>
              <th>8 Pax</th>
            </tr>
            <tr>
              <td>Heathrow</td>
              <td>£100</td>
              <td>£115</td>
              <td>£144</td>
              <td>£156</td>
            </tr>
            <tr>
              <td>Stansted</td>
              <td>£132</td>
              <td>£150</td>
              <td>£192</td>
              <td>£204</td>
            </tr>
            <tr>
              <td>Gatwick</td>
              <td>£132</td>
              <td>£150</td>
              <td>£192</td>
              <td>£204</td>
            </tr>
            <tr>
              <td>Luton</td>
              <td>£132</td>
              <td>£150</td>
              <td>£192</td>
              <td>£204</td>
            </tr>
            <tr>
              <td>London City</td>
              <td>£100</td>
              <td>£115</td>
              <td>£144</td>
              <td>£156</td>
            </tr>
          </table>

        <br /><br /><br />
      </div>

      <Footer />
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={() => setIsPopupOpen(false)}
        confirmButtonText="OK"
      />
    </>
  );
}

export default Taxi;