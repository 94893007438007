import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

import '../../../styles/admin/adminElse.css';
import NotFound from '../../../components/NotFound';

function NewTrip() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [categoriesData, setCategoriesData] = useState({});
  const [loadingCategoriesData, setLoadingCategoriesData] = useState({});

  const [name, setName] = useState('');
  const [nameShort, setNameShort] = useState('');
  const [tags, setTags] = useState('');
  const [description, setDescription] = useState('');
  const [destination, setDestination] = useState('');
  const [departureDate, setDepartureDate] = useState();
  const [returnDate, setReturnDate] = useState();
  const [itinerary, setItinerary] = useState('');
  const [itineraryPdf, setItineraryPdf] = useState('');
  const [hotelStars, setHotelStars] = useState('');
  const [price, setPrice] = useState();
  const [priceChild, setPriceChild] = useState();
  const [priceNewborn, setPriceNewborn] = useState();
  const [whatIncluded, setWhatIncluded] = useState('');
  const [whatNotIncluded, setWhatNotIncluded] = useState('');
  const [policy, setPolicy] = useState('');
  const [availablePlaces, setAvailablePlaces] = useState();
  const [collaboratorEmail, setCollaboratorEmail] = useState('');
  const [externalLink, setExternalLink] = useState('');
  const [externalForm, setExternalForm] = useState('');
  const [status, setStatus] = useState('');
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState();
  const [visible, setVisible] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    // Fetch Tour Data by category
    setLoadingCategoriesData(true);
    axios.get(`https://api.mylondoncorner.com/categories`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setCategoriesData(response.data);
      setLoadingCategoriesData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingCategoriesData(false);
    });
  }, []);

  const handleTripSubmission = async () => {
    if (
      name !== '' &&
      nameShort !== '' &&
      tags !== '' &&
      description !== '' &&
      destination !== '' &&
      departureDate !== null &&
      returnDate !== null &&
      itineraryPdf !== '' &&
      hotelStars !== '' &&
      price !== null &&
      priceChild !== null &&
      priceNewborn !== null &&
      whatIncluded !== null &&
      whatNotIncluded !== null &&
      policy !== null &&
      availablePlaces !== null &&
      categories.length !== 0 &&
      images != null &&
      visible !== null
    ) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('nameShort', nameShort);
      formData.append('tags', tags);
      formData.append('description', description);
      formData.append('destination', destination);
      formData.append('departureDate', departureDate);
      formData.append('returnDate', returnDate);
      formData.append('itinerary', itinerary);
      formData.append('itineraryPdf', itineraryPdf);
      formData.append('hotelStars', hotelStars);
      formData.append('price', price);
      formData.append('priceChild', priceChild);
      formData.append('priceNewborn', priceNewborn);
      formData.append('whatIncluded', whatIncluded);
      formData.append('whatNotIncluded', whatNotIncluded);
      formData.append('policy', policy);
      formData.append('availablePlaces', availablePlaces);
      formData.append('collaboratorEmail', collaboratorEmail);
      formData.append('externalLink', externalLink);
      formData.append('externalForm', externalForm);
      formData.append('status', status);
      formData.append('categories[]', categories);
      if (images) {
        Array.from(images).forEach((file) => formData.append('images', file));
      }
      formData.append('visible', visible);

      axios.post('https://api.mylondoncorner.com/trip/save', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-KEY': process.env.REACT_APP_API_KEY
          }
        })
        .then((response) => {
          if (response.status === 200) {
            navigate('/admin/trips');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      alert('Required fields must be typed in!');
    }
  };

  return (
    <>
      {loadingUserData || loadingCategoriesData ? (
        <NotFound />
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

                <br /><br />

                <div className='admin-container'>

                  <h1>New Trip</h1>

                  <br /><br />

                  <p>Visible</p>
                  <select id="visible" name="visible" onChange={(e) => setVisible(e.target.value)}>
                    <option value="" disabled selected>Select an option</option>
                    <option value="1">true</option>
                    <option value="0">false</option>
                  </select>

                  <br /><br />

                  <p>Name</p>
                  <input type='text' id="name" name="name" onChange={(e) => setName(e.target.value)} placeholder='Insert name' />

                  <br /><br />

                  <p>Short Name</p>
                  <input type='text' id="nameShort" name="nameShort" onChange={(e) => setNameShort(e.target.value)} placeholder='Insert short name' />

                  <br /><br />

                  <p>Tags</p>
                  <input type='text' id="tags" name="tags" onChange={(e) => setTags(e.target.value)} placeholder='Insert tags, 1, 2, 3...' />

                  <br /><br />

                  <p>Description</p>
                  <textarea id="description" rows={5} name="description" onChange={(e) => setDescription(e.target.value)} placeholder='Insert description' />

                  <br /><br />

                  <p>Destination</p>
                  <input type='text' id="destination" name="destination" onChange={(e) => setDestination(e.target.value)} placeholder='Insert destination' />

                  <br /><br />

                  <p>Departure Date</p>
                  <input type='date' id="departureDate" name="departureDate" onChange={(e) => setDepartureDate(e.target.value)} />

                  <br /><br />

                  <p>Return Date</p>
                  <input type='date' id="returnDate" name="returnDate" onChange={(e) => setReturnDate(e.target.value)} />

                  <br /><br />

                  <p>Itinerary</p>
                  <textarea id="itinerary" rows={5} name="itinerary" onChange={(e) => setItinerary(e.target.value)} placeholder='Insert itinerary' />

                  <br /><br />

                  <p>Itinerary Pdf</p>
                  <input type='text' id="itineraryPdf" name="itineraryPdf" onChange={(e) => setItineraryPdf(e.target.value)} placeholder='Insert itinerary pdf link' />

                  <br /><br />

                  <p>Hotel Stars</p>
                  <select id="hotelStars" name="hotelStars" onChange={(e) => setHotelStars(e.target.value)}>
                    <option value="" disabled selected>Select an option</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>

                  <br /><br />

                  <p>Price</p>
                  <input type='number' id="price" name="price" onChange={(e) => setPrice(e.target.value)} placeholder='Insert price' />

                  <br /><br />

                  <p>Price child</p>
                  <input type='number' id="priceChild" name="priceChild" onChange={(e) => setPriceChild(e.target.value)} placeholder='Insert child price' />

                  <br /><br />

                  <p>Price newborn</p>
                  <input type='number' id="priceNewborn" name="priceNewborn" onChange={(e) => setPriceNewborn(e.target.value)} placeholder='Insert newborn price' />

                  <br /><br />

                  <p>What's included •</p>
                  <textarea id="whatIncluded" rows={6} name="whatIncluded" onChange={(e) => setWhatIncluded(e.target.value)} placeholder='• Insert what is included' />

                  <br /><br />

                  <p>What's NOT included •</p>
                  <textarea id="whatNotIncluded" rows={6} name="whatNotIncluded" onChange={(e) => setWhatNotIncluded(e.target.value)} placeholder='• Insert what is NOT included' />

                  <br /><br />

                  <p>Policy •</p>
                  <textarea id="policy" rows={6} name="policy" onChange={(e) => setPolicy(e.target.value)} placeholder='• Insert policy' />

                  <br /><br />

                  <p>Available places</p>
                  <input type='number' id="availablePlaces" name="availablePlaces" onChange={(e) => setAvailablePlaces(e.target.value)} placeholder='Insert available places' />

                  <br /><br />

                  <p>Collaborator Email</p>
                  <input type='text' id="collaboratorEmail" name="collaboratorEmail" onChange={(e) => setCollaboratorEmail(e.target.value)} placeholder='Insert collaborator email' />

                  <br /><br />

                  <p>External Link</p>
                  <input type='text' id="externalLink" name="externalLink" onChange={(e) => setExternalLink(e.target.value)} placeholder='Insert external link' />

                  <br /><br />

                  <p>External Form</p>
                  <input type='text' id="externalForm" name="externalForm" onChange={(e) => setExternalForm(e.target.value)} placeholder='Insert external form' />

                  <br /><br />

                  <p>Status</p>
                  <select id="status" name="status" onChange={(e) => setStatus(e.target.value)}>
                  <option value="" disabled selected>Select an option</option>
                    <option value="open">Open</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="sold out">Sold out</option>
                  </select>

                  <p>Categories</p>
                  <select
                    id="categories"
                    name="categories"
                    multiple
                    onChange={(e) => {
                      const selectedCategoryIds = Array.from(e.target.selectedOptions, option => option.value);
                      setCategories(selectedCategoryIds);
                    }}
                  >
                    {categoriesData.categories.map(category => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>

                  <br /><br />

                  <p>Cover images (1200x681)px</p>
                  <input type="file" name="images" onChange={(e) => setImages(e.target.files)} multiple required />

                  <br /><br /><br />

                  <button className='button' onClick={handleTripSubmission}>Submit</button>

                </div>

              <Footer />
            </>
          ) : (
            <NotFound />
          )
        ) : (
          <NotFound />
        )
      )}
    </>
  );
}

function convertDatesToString(datesString) {
  const datesArray = datesString.split(',');
  const formattedDates = datesArray.map(dateString => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month index starts from 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  });
  return formattedDates.join(',');
}

export default NewTrip;