import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';

function TermsAndConditionsHp() {
  return (
    <>
      <Navbar />
      <br /><br />
      <div className='home-container' style={{ textAlign: 'left' }}>
        <h1 style={{ textAlign: 'center' }}>Termini e Condizioni</h1>
        <br /><br /><br />
        <p>
          Questi sono i nostri termini e condizioni per l'utilizzo del nostro sito web mylondoncorner.com e la prenotazione e acquisto di viaggi tramite il sito web.
        </p>
        <br />
        <p>
          Se hai domande su questi termini, ti preghiamo di contattarci utilizzando il <a href="/contattaci">modulo di contatto</a>.
        </p>
        <br />
        <p>
          I termini e le condizioni qui contenuti, insieme a qualsiasi avviso o condizione su altre aree di questo sito Web, regoleranno tutti insieme l'utilizzo da parte dei clienti di questo sito Web. Si noti che mylondoncorner può in qualsiasi momento apportare modifiche o rimuovere parte di questo sito Web senza alcuna responsabilità nei confronti dei clienti per tali modifiche. Mylondoncorner si riserva il diritto di modificare questi termini e condizioni in futuro senza informare specificamente i clienti e l'uso continuato del sito Web o l'effettuazione di ordini dopo che tali modifiche saranno considerate come riconoscimento e accettazione delle stesse.
        </p>
        <br /><br />
        <h2>Spese di prenotazione</h2>
        <br />
        <p>
          Le spese di prenotazione ammontano a € 35,00 per persona: tale importo è uguale sia per gli adulti che per i bambini e comprendono le spese di gestione pratica, l'assistenza e l'assicurazione medico-bagaglio obbligatoria I4T (vedi paragrafo relativo).
        </p>
        <br /><br />
        <h2>Prenotazioni</h2>
        <br />
        <p>
          La domanda di prenotazione dovrà essere redatta su apposito modulo contrattuale, compilato in ogni sua parte e sottoscritto dal cliente. L'accettazione delle prenotazioni si intende perfezionata, con conseguente conclusione positiva del contratto, solo nel momento in cui il cliente pagherà l'acconto relativo, tramite carta di credito o bonifico (inviando quindi relativa copia contabile). A seguire, l'organizzatore invierà la conferma di prenotazione, anche a mezzo sistema telematico, al cliente.
        </p>
        <br /><br />
        <h2>Pagamenti</h2>
        <br />
        <p>
          All'atto della prenotazione dovrà essere versato, a titolo di ACCONTO, il 50% del prezzo del pacchetto turistico (salvo quando diversamente indicato). Il SALDO dovrà essere versato almeno 30 giorni prima della prevista partenza, oppure in concomitanza con la prenotazione, se questa è effettuata nei 30 giorni antecedenti la partenza. Il mancato pagamento delle somme predette, alle date stabilite, costituisce clausola risolutiva espressa tale da determinare, la risoluzione di diritto, con conseguente applicazione delle penali previste.
        </p>
        <br /><br />
        <h2>Modalità di pagamento</h2>
        <br />
        <p>
          Il cliente potrà corrispondere acconto e saldo con bonifico bancario. Tutti i dati saranno inviati tramite email al momento della conferma.
        </p>
        <br /><br />
        <h2>Obblighi dei partecipanti</h2>
        <br />
        <p>
          I partecipanti al momento della partenza dovranno essere muniti di passaporto individuale o di altro documento idoneo per tutti i paesi toccati dall'itinerario, nonché dei visti di soggiorno e di transito e dei certificati sanitari che fossero eventualmente richiesti. Inoltre dovranno accertarsi della residua validità del documento in relazione alle regole d'ingresso richieste dai vari paesi. Il cliente comunicherà per iscritto all'organizzatore, all'atto della prenotazione, le particolari richieste personali che potranno formare oggetto di accordi specifici sulle modalità del viaggio, sempre che ne risulti possibile l'attuazione.
        </p>
        <br /><br />
        <h2>Voli</h2>
        <br />
        <p>
          Tutti i voli prenotati tramite l'organizzatore non sono rimborsabili: in caso di cancellazione non è previsto alcun rimborso della tariffa. A volte la tariffa può consentire modifiche dietro il pagamento di un supplemento, il consiglio è comunque quello di chiedere sempre ai nostri uffici le regole tariffarie in fase di prenotazione, per capire se e quali modifiche sono concesse sull'itinerario.
        </p>
        <br />
        <p>
          Offriamo anche servizio di consulenza per prenotazione autonoma dei voli, anche in questo caso, non è previsto alcun rimborso della tariffa.
        </p>
        <br /><br />
        <h2>Partenze Garantite</h2>
        <br />
        <p>
          Ricordiamo al gentile cliente che i nostri viaggi di gruppo sono a partenze garantite. Questo vuol dire che anche se il gruppo non viene creato c'è la possibilità di partire sempre. Minimo garantito è da due persone in poi.
        </p>
        <br /><br />
        <h2>Tour in auto / Noleggio auto</h2>
        <br />
        <p>
          Per noleggiare un'auto è obbligatorio che l'intestatario della prenotazione sia titolare di una carta di credito a 16 cifre, con numeri in rilievo, non prepagata e non electron. Tale requisito è fondamentale per poter ritirare il veicolo. L'organizzatore non è responsabile in alcun modo per noleggi non usufruiti per questo motivo.
        </p>
        <br /><br />
        <h2>Modifiche prima della partenza</h2>
        <br />
        <p>
          Le modifiche richieste dal cliente a prenotazioni già effettuate non obbligano l'organizzatore nei casi in cui non possano essere soddisfatte. Se la richiesta di modifica comportasse eventuali spese accessorie a carico del cliente, le stesse verranno comunicate tempestivamente.
        </p>
        <br /><br />
        <h2>Modifiche dopo la partenza</h2>
        <br />
        <p>
          L'organizzatore, qualora dopo la partenza si trovi nell'impossibilità di fornire per qualsiasi ragione, tranne che per un fatto proprio del consumatore, una parte essenziale dei servizi contemplati in contratto, dovrà predisporre soluzioni alternative, senza supplementi di prezzo a carico del contraente e qualora le prestazioni fornite siano di valore inferiore rispetto a quelle previste, rimborsarlo in misura pari a tale differenza. Qualora non risulti possibile alcuna soluzione alternativa, ovvero la soluzione predisposta dall'organizzatore venga rifiutata dal consumatore per seri e giustificati motivi, l'organizzatore rimborserà il cliente nella misura della differenza tra il costo delle prestazioni previste e quello delle prestazioni effettuate.
        </p>
        <br /><br />
        <h2>Penali di cancellazione</h2>
        <br />
        <p>
          - 10% della caparra per cancellazioni dal giorno della conferma fino alla data definita per pagamento totale;
        </p>
        <br />
        <p>
          - 50% del totale prenotazione per cancellazioni dal giorno della conferma a 8 giorni prima della partenza;
        </p>
        <br />
        <p>
          - 100% del totale prenotazione per cancellazioni dopo tale termine o mancata presentazione;
        </p>
        <br /><br />
        <h2>Importante per cancellazioni e/o modifiche</h2>
        <br />
        <p>
          Alcuni hotel e la maggior parte dei servizi hanno tariffe non rimborsabili in quanto offerte speciali o promozioni stagionali. Pertanto, in caso di modifica o cancellazione di tali hotel e servizi non è previsto alcun rimborso indipendentemente dalla data di cancellazione. In fase di conferma consigliamo ai clienti di verificare con i nostri uffici l'eventuale presenza di hotel o servizi con regole e penali particolari e più restrittive rispetto a quanto riportato qui sopra.
        </p>
        <br /><br />
        <h2>Come cancellare una prenotazione</h2>
        <br />
        <p>
          Per cancellare una prenotazione è necessario inviare un'e-mail ai nostri uffici indicando il riferimento della prenotazione, i vostri dati personali ed i vostri recapiti. L'organizzatore rimborserà la quota di partecipazione già versata ad esclusione degli importi di penale come indicato nel paragrafo “Penali di cancellazione”.
        </p>
        <br /><br />
        <h2>Assicurazioni</h2>
        <br />
        <p>
          COPERTURA BASE MEDICO E BAGAGLIO (inclusa nelle spese di prenotazione): <a href="https://api.mylondoncorner.com/pdf/legal/in-viaggio-con-hp/assicurazione-medico-bagaglio-hp.pdf" style={{ color: 'red' }}>assicurazione-medico-bagaglio.pdf</a>
        </p>
        <br />
        <p>
          La polizza I4T compresa nelle quote non è rimborsabile in caso di cancellazione.
        </p>
        <br />
        <p>
          INTEGRAZIONE FACOLTATIVA POLIZZA ANNULLAMENTO (non inclusa nelle spese di prenotazione): <a href="https://api.mylondoncorner.com/pdf/legal/in-viaggio-con-hp/assicurazione-medico-bagaglio-annullamento-hp.pdf" style={{ color: 'red' }}>assicurazione-medico-bagaglio-annullamento.pdf</a>
        </p>
        <br />
        <p>
          La polizza I4T di annullamento viaggio è facoltativa e deve tassativamente essere stipulata contestualmente alla conferma del viaggio. Il costo fisso è pari a € 25,00 a persona per viaggi da 1 a 4 notti e € 50,00 a persona per viaggi pari o superiori a 5 notti.
        </p>
        <br /><br />
        <h2>Importante</h2>
        <br />
        <p>
          Ricordiamo che non è previsto alcun rimborso nel caso il viaggiatore al momento della partenza non sia provvisto dei documenti richiesti per l'uscita, l'ingresso o il transito nei Paesi coinvolti nel viaggio. Eventuali spese di riprotezione dovute a tale mancanza saranno a carico del viaggiatore.
        </p>
        <br /><br />
        <p><b>L'acquisto sulla nostra piattaforma o presso il nostro ufficio equivale all'accettazione automatica dei nostri termini e condizioni.</b></p>
        <br /><br />
        <h2>Riferimenti</h2>
        <br />
        <p>MYLONDONCORNER</p>
        <p>info@mylondoncorner.com</p>
        <p>www.mylondoncorner.com</p>
        <br />
        <p>BINARIO 9¾ VIAGGI</p>
        <p>hp@binario934.it</p>
        <p>www.binario934.it</p>
      </div>
      <Footer />
    </>
  );
}

export default TermsAndConditionsHp;
