import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// Icons
import { airplane, call, card } from 'ionicons/icons'; // Importing icons from Ionicons
import { IonIcon } from '@ionic/react'; // Importing IonIcon component from @ionic/react

// Navigation
import { useNavigate } from 'react-router-dom';

// Components
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';
import FeedbackPopup from '../components/widgets/FeedbackPopup';

// Styles
import '../styles/otherProducts.css';
import '../styles/tables.css';
import '../styles/inputs.css';

function PersonalisedItineraries() {
  const navigate = useNavigate();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [days, setDays] = useState(0);
  const [accomodation, setAccomodation] = useState('');
  const [preferences, setPreferences] = useState('');

  useEffect(() => {
    // Calculate the default pickup date (2 days from now)
    const now = new Date();
    // Andata
    now.setDate(now.getDate() + 7);
    var formattedDate = now.toISOString().split('T')[0]; // Format date to YYYY-MM-DD
    setArrivalDate(formattedDate); // Set default pickup date
    // Ritorno
    now.setDate(now.getDate() + 3);
    formattedDate = now.toISOString().split('T')[0]; // Format date to YYYY-MM-DD
    setDepartureDate(formattedDate); // Set default pickup date
  }, []); // Empty dependency array to run only on mount

  // Function to calculate the number of days between two dates
  const calculateDays = (arrival, departure) => {
    const arrivalDate = new Date(arrival);
    const departureDate = new Date(departure);
    const differenceInTime = departureDate.getTime() - arrivalDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1; // +1 to include both days
    setDays(differenceInDays);
  };

  // Call the calculateDays function whenever the dates change
  useEffect(() => {
    if (arrivalDate && departureDate) {
      calculateDays(arrivalDate, departureDate);
    }
  }, [arrivalDate, departureDate]);

  const handleItinerarySubmission = async () => {
    if (name && email && arrivalDate && departureDate && accomodation && email.includes('@')) {
      let message = `
        <div>
          <ul>
            <li><strong>Arrival Date:</strong> ${arrivalDate}</li>
            <li><strong>Departure Date:</strong> ${departureDate}</li>
            <li><strong>Number of Days:</strong> ${days}</li> <!-- Display number of days -->
            <li><strong>Accomodation:</strong> ${accomodation}</li>
            <li><strong>Preferences:</strong> ${preferences}</li>
          </ul>
        </div>
      `;

      axios.post('https://api.mylondoncorner.com/contact', {
        subject: 'Richiesta Itinerario Personalizzato',
        name,
        email,
        message
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
        .then((response) => {
          if (response.status === 200) {
            navigate('/contact/received');
          }
        })
        .catch((error) => {
          console.error('Error sending data:', error);
        });
    } else {
      setFeedbackMessage(email.includes('@') ? "Tutti i campi devono essere compilati!" : "E-mail non valida!");
      setIsPopupOpen(true);
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Itinerari Personalizzati</title>
        <meta name="description" content="Lascia organizzare a noi il tuo itinerario!" />
      </Helmet>

      <Navbar />

      <br /><br />

      <div className='post-container'>
        <h2>Prenota il tuo Itinerario Personalizzato</h2>
        <br />
        <p>Itinerari Personalizzati ad-hoc in base alle tue esigenze</p>
        <br /><br />

        <div className='row'>
          <div className='other-products-column-input-3'>
            <p>Inserisci Data di arrivo</p>
            <input
              type='date'
              value={arrivalDate}
              onChange={(e) => setArrivalDate(e.target.value)}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci Data della partenza</p>
            <input
              type='date'
              value={departureDate}
              onChange={(e) => setDepartureDate(e.target.value)}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Durata del soggiorno</p>
            <input
              type='text'
              value={
                days + ' ' + (days === 1 ? 'giorno' : 'giorni') + ' | £' + 
                (days === 1 ? '15' : 
                days === 2 ? '25' :
                days === 3 ? '35' :
                days === 4 ? '45' :
                days === 5 ? '60' :
                days === 6 ? '75' :
                days === 7 ? '90' :
                days === 8 ? '110' : '--')
              }
              readOnly
              onChange={(e) => setDepartureDate(e.target.value)}
            />
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='other-products-column-input-3'>
            <p>Inserisci il tuo Nome</p>
            <input
              type='text'
              value={name}
              // placeholder='Inserisci il tuo Nome'
              onChange={(e) => setName(e.target.value)}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci la tua E-mail</p>
            <input
              type='email'
              value={email}
              // placeholder='Inserisci la tua E-mail'
              onChange={(e) => setEmail(e.target.value)}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci l'indirizzo del tuo alloggio</p>
            <input
              type='text'
              value={accomodation}
              // placeholder='Inserisci la tua E-mail'
              onChange={(e) => setAccomodation(e.target.value)}
            />
            <br /><br />
          </div>
        </div>

        <div className='row'>
          <div className='other-products-column-input-1'>
            <p>Cosa vorresti vedere?</p>
            <textarea
              value={preferences}
              placeholder='Cosa non puoi assolutamente perdere? Qualche attrazione?'
              rows={5}
              onChange={(e) => setPreferences(e.target.value)}
            >
            </textarea>
            <br /><br />
          </div>
        </div>

        <br />

        <button className='button' onClick={handleItinerarySubmission}>Richiedi</button>

        <br /><br /><br />

        <div className='other-products-column-input-3-icons-row'>
          <div className='other-products-column-input-3-icons'>
            <IonIcon icon={airplane} />
            <div className='other-products-column-input-3-icons-text'>
              <b>Itinerario personalizzato</b><br />
              Ogni itinerario è creato su misura, considerando le tue preferenze di viaggio.
            </div>
          </div>
          <div className='other-products-column-input-3-icons'>
            <IonIcon icon={card} />
            <div className='other-products-column-input-3-icons-text'>
              <b>Esperienze autentiche</b><br />
              Scopri attrazioni e luoghi locali selezionati per vivere un'esperienza autentica.
            </div>
          </div>
          <div className='other-products-column-input-3-icons'>
            <IonIcon icon={call} />
            <div className='other-products-column-input-3-icons-text'>
              <b>Flessibilità</b><br />
              Modifica il tuo itinerario in qualsiasi momento, con supporto dedicato in italiano.
            </div>
          </div>
        </div>

        <br /><br /><br />

        <h2>Cosa è incluso?</h2>

        <br />

        <p>• Realizzazione di un dettagliato itinerario personalizzato consegnato in formato PDF via email.</p><br />
        <p>• Prenotazioni per attrazioni e tour.</p><br />
        <p>• Consigli su ristoranti, fast food e pub.</p><br />
        <p>• Informazioni utili per il tuo viaggio.</p><br />
        <p>• Supporto in italiano prima e durante il tuo soggiorno a Londra via E-mail.</p>

        <br /><br />

        <table>
            <tr>
              <th>Giorni di itinerario</th>
              <th>Costo</th>
            </tr>
            <tr>
              <td>1</td>
              <td>£15</td>
            </tr>
            <tr>
              <td>2</td>
              <td>£25</td>
            </tr>
            <tr>
              <td>3</td>
              <td>£35</td>
            </tr>
            <tr>
              <td>4</td>
              <td>£45</td>
            </tr>
            <tr>
              <td>5</td>
              <td>£60</td>
            </tr>
            <tr>
              <td>6</td>
              <td>£75</td>
            </tr>
            <tr>
              <td>7</td>
              <td>£90</td>
            </tr>
            <tr>
              <td>8</td>
              <td>£110</td>
            </tr>
            <tr>
              <td>Piú giorni</td>
              <td>£--</td>
            </tr>
          </table>

        <br /><br /><br />
      </div>

      <Footer />
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={() => setIsPopupOpen(false)}
        confirmButtonText="OK"
      />
    </>
  );
}

export default PersonalisedItineraries;