import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

import CalendarDateSelector from "../../../components/tour/CalendarDateSelector";

import '../../../styles/admin/adminElse.css';
import NotFound from '../../../components/NotFound';

function NewTour() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [categoriesData, setCategoriesData] = useState({});
  const [loadingCategoriesData, setLoadingCategoriesData] = useState({});

  const [visible, setVisible] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [rate, setRate] = useState();
  const [price, setPrice] = useState();
  const [priceChild, setPriceChild] = useState();
  const [minimumAge, setMinimumAge] = useState();
  const [purchasable, setPurchasable] = useState(false);
  const [availableDates, setAvailableDates] = useState();
  const [availableSpaces, setAvailableSpaces] = useState();
  const [meetingPoint, setMeetingPoint] = useState();
  const [meetingTime, setMeetingTime] = useState();
  const [endingPoint, setEndingPoint] = useState();
  const [pricePrivate, setPricePrivate] = useState();
  const [latitute, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [carPrice, setCarPrice] = useState(0);
  const [carGuidePrice, setCarGuidePrice] = useState(0);
  const [embedAMap, setEmbedAMap] = useState('');
  const [highlights, setHighlights] = useState('');
  const [duration, setDuration] = useState();
  const [whatIncluded, setWhatIncluded] = useState('');
  const [whatNotIncluded, setWhatNotIncluded] = useState('');
  const [whatToBring, setWhatToBring] = useState('');
  const [categories, setCategories] = useState([]);
  const [externalLink, setExternalLink] = useState();
  const [images, setImages] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    // Fetch Tour Data by category
    setLoadingCategoriesData(true);
    axios.get(`https://api.mylondoncorner.com/categories`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setCategoriesData(response.data);
      setLoadingCategoriesData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingCategoriesData(false);
    });
  }, []);

  const handlePostSubmission = async () => {
    if (
      visible !== null &&
      name !== '' &&
      description !== '' &&
      tags !== '' &&
      rate !== null &&
      price !== null &&
      priceChild !== null &&
      categories.length !== 0 &&
      minimumAge !== null &&
      images != null
    ) {
      const formData = new FormData();
      formData.append('visible', visible);
      formData.append('name', name);
      formData.append('description', description);
      formData.append('content', content);
      formData.append('tags', tags);
      formData.append('rate', rate);
      formData.append('price', price);
      formData.append('priceChild', priceChild);
      formData.append('duration', duration);
      formData.append('minimumAge', minimumAge);
      formData.append('purchasable', purchasable);
      formData.append('categories[]', categories);
      formData.append('externalLink', externalLink);
      formData.append('images', images);

      if(purchasable) {
        formData.append('availableDates', convertDatesToString(availableDates.toString()));
        formData.append('availableSpaces', availableSpaces);
        formData.append('meetingPoint', meetingPoint);
        formData.append('meetingTime', meetingTime);
        formData.append('endingPoint', endingPoint);
        formData.append('pricePrivate', pricePrivate);
        formData.append('latitute', latitute);
        formData.append('longitude', longitude);
        formData.append('carPrice', carPrice);
        formData.append('carGuidePrice', carGuidePrice);
        formData.append('embedAMap', embedAMap);
        formData.append('highlights', highlights);
        formData.append('whatIncluded', whatIncluded);
        formData.append('whatNotIncluded', whatNotIncluded);
        formData.append('whatToBring', whatToBring);
      }
      
      console.log([...formData])

      axios.post('https://api.mylondoncorner.com/tour/save', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-KEY': process.env.REACT_APP_API_KEY
          }
        })
        .then((response) => {
          if (response.status === 200) {
            navigate('/admin/tours');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      alert('Required fields must be typed in!');
    }
  };  

  const handleDateChange = (dates) => {
    setAvailableDates(dates);
  };

  return (
    <>
      {loadingUserData || loadingCategoriesData ? (
        <NotFound />
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

                <br /><br />

                <div className='admin-container'>

                  <h1>New Tour</h1>

                  <br /><br />

                  <p>Visible</p>
                  <select id="visible" name="visible" onChange={(e) => setVisible(e.target.value)}>
                    <option value="" disabled selected>Select an option</option>
                    <option value="1">true</option>
                    <option value="0">false</option>
                  </select>

                  <br /><br />

                  <p>Name</p>
                  <input type='text' id="name" name="name" onChange={(e) => setName(e.target.value)} placeholder='Insert name' />

                  <br /><br />

                  <p>Description</p>
                  <textarea id="description" rows={5} name="description" onChange={(e) => setDescription(e.target.value)} placeholder='Insert description' />

                  <br /><br />

                  <p>Content (HTML)</p>
                  <textarea id="content" rows={15} name="content" onChange={(e) => setContent(e.target.value)} placeholder='Insert content' />

                  <br /><br />

                  <p>Tags</p>
                  <input type='text' id="tags" name="tags" onChange={(e) => setTags(e.target.value)} placeholder='Insert tags, 1, 2, 3...' />

                  <br /><br />

                  <p>Rate</p>
                  <select id="rate" name="rate" onChange={(e) => setRate(e.target.value)}>
                    <option value="" disabled selected>Select an option</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>

                  <br /><br />

                  <p>Price</p>
                  <input type='number' id="price" name="price" onChange={(e) => setPrice(e.target.value)} placeholder='Insert price' />

                  <br /><br />

                  <p>Price child</p>
                  <input type='number' id="priceChild" name="priceChild" onChange={(e) => setPriceChild(e.target.value)} placeholder='Insert child price' />

                  <br /><br />

                  <p>Duration</p>
                  <input type='text' id="duration" name="duration" onChange={(e) => setDuration(e.target.value)} placeholder='Insert duration' />

                  <br /><br />

                  <p>Minimum age</p>
                  <input type='number' id="minimumAge" name="minimumAge" onChange={(e) => setMinimumAge(e.target.value)} placeholder='Insert minimum age' />

                  <br /><br />

                  <p>Is it sold by us?</p>
                  <select id="purchasable" name="purchasable" onChange={(e) => setPurchasable(e.target.value)}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>

                  <br /><br />

                  {purchasable ? (
                    <>
                      <CalendarDateSelector onDateChange={handleDateChange} />

                      <br /><br />

                      <p>Available spaces</p>
                      <input type='number' id="availableSpaces" name="availableSpaces" onChange={(e) => setAvailableSpaces(e.target.value)} placeholder='Insert available spaces' />

                      <br /><br />

                      <p>Meeting point</p>
                      <input type='text' id="meetingPoint" name="meetingPoint" onChange={(e) => setMeetingPoint(e.target.value)} placeholder='Insert meeting point' />

                      <br /><br />

                      <p>Meeting time</p>
                      <input type='time' id="meetingTime" name="meetingTime" onChange={(e) => setMeetingTime(e.target.value)} placeholder='Insert meeting time' />

                      <br /><br />

                      <p>Ending point</p>
                      <input type='text' id="endingPoint" name="endingPoint" onChange={(e) => setEndingPoint(e.target.value)} placeholder='Insert ending point' />

                      <br /><br />

                      <p>Latitude</p>
                      <input type='text' id="latitude" name="latitude" onChange={(e) => setLatitude(e.target.value)} placeholder='Insert latitude' />

                      <br /><br />

                      <p>Longitude</p>
                      <input type='number' id="longitude" name="longitude" onChange={(e) => setLongitude(e.target.value)} placeholder='Insert longitude' />

                      <br /><br />

                      <p>Car price (If car tour)</p>
                      <input type='number' id="carPrice" name="carPrice" onChange={(e) => setCarPrice(e.target.value)} placeholder='Insert car price' />
                      
                      <br /><br />

                      <p>Car Guide price (If car tour)</p>
                      <input type='number' id="carGuidePrice" name="carGuidePrice" onChange={(e) => setCarGuidePrice(e.target.value)} placeholder='Insert car guide price' />
                      
                      <br /><br />

                      <p>Price private</p>
                      <input type='number' id="pricePrivate" name="pricePrivate" onChange={(e) => setPricePrivate(e.target.value)} placeholder='Insert available spaces' />
                      
                      <br /><br />

                      <p>Embed a map script</p>
                      <input type='text' id="embedAMap" name="embedAMap" onChange={(e) => setEmbedAMap(e.target.value)} placeholder='Insert embed a map script' />

                      <br /><br />

                      <p>Highlights •</p>
                      <textarea id="highlights" rows={6} name="highlights" onChange={(e) => setHighlights(e.target.value)} placeholder='• Insert highlights' />

                      <br /><br />

                      <p>What's included •</p>
                      <textarea id="whatIncluded" rows={6} name="whatIncluded" onChange={(e) => setWhatIncluded(e.target.value)} placeholder='• Insert what is included' />

                      <br /><br />

                      <p>What's NOT included •</p>
                      <textarea id="whatNotIncluded" rows={6} name="whatNotIncluded" onChange={(e) => setWhatNotIncluded(e.target.value)} placeholder='• Insert what is NOT included' />

                      <br /><br />

                      <p>What to bring •</p>
                      <textarea id="whatToBring" rows={6} name="whatToBring" onChange={(e) => setWhatToBring(e.target.value)} placeholder='• Insert what to bring' />

                      <br /><br />
                    </>

                  ) : null}

                  <p>Categories</p>
                  <select
                    id="categories"
                    name="categories"
                    multiple
                    onChange={(e) => {
                      const selectedCategoryIds = Array.from(e.target.selectedOptions, option => option.value);
                      setCategories(selectedCategoryIds);
                    }}
                  >
                    {categoriesData.categories.map(category => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>

                  <br /><br />

                  <p>External Link</p>
                  <input type='text' id="externalLink" name="externalLink" onChange={(e) => setExternalLink(e.target.value)} placeholder='Insert external link' />

                  <br /><br />

                  <p>Cover images (1200x681)px</p>
                  <input type="file" name="images" onChange={(e) => setImages(e.target.files[0])} multiple required/>

                  <br /><br /><br />

                  <button className='button' onClick={handlePostSubmission}>Submit</button>

                </div>

              <Footer />
            </>
          ) : (
            <NotFound />
          )
        ) : (
          <NotFound />
        )
      )}
    </>
  );
}

function convertDatesToString(datesString) {
  const datesArray = datesString.split(',');
  const formattedDates = datesArray.map(dateString => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month index starts from 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  });
  return formattedDates.join(',');
}

export default NewTour;