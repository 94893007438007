import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

import NotFound from "../../../components/NotFound";

function AdminNewsletterUsers() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [newsletterUsersData, setnewsletterUsersData] = useState({});
  const [loadingNewsletterUsers, setloadingNewsletterUsers] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    setloadingNewsletterUsers(true);
    axios.get(`https://api.mylondoncorner.com/newsletter/users`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setnewsletterUsersData(response.data);
      setloadingNewsletterUsers(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setloadingNewsletterUsers(false);
    });
  }, []);

  const handleDelete = async (newsletterUserId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this newsletter user?");
    if (confirmDelete) {
      try {
        await axios.delete(`https://api.mylondoncorner.com/newsletter/user/${newsletterUserId}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY
          }
        });
        // Reload newsletterUsers data after successful deletion
        setloadingNewsletterUsers(true);
        const newsletterUsersResponse = await axios.get(`https://api.mylondoncorner.com/newsletter/users`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY
          }
        });
        setnewsletterUsersData(newsletterUsersResponse.data);
        setloadingNewsletterUsers(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setloadingNewsletterUsers(false);
      }
    }
  };
  
  return (
    <div className="table-container">
      {loadingUserData ? (
        <NotFound />
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

              {loadingNewsletterUsers ? (
                <NotFound />
              ) : (
                <>
                  <br />
                  <button className='button' onClick={() => navigate('/admin/newsletter/user/new')}>New</button>
                  <br />
                  <table>
                    <tr>
                      <th>Active</th>
                      <th>Name</th>
                      <th>Surname</th>
                      <th>Email</th>
                      <th>Birthday</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Postcode</th>
                      <th>Date</th>
                      <th>Delete</th>
                    </tr>
                    {newsletterUsersData.newsletterUsers.map((newsletterUser, index) => (
                      <tr key={index}>
                        <td>{newsletterUser.active ? 'Active' : 'Unsubscriber'}</td>
                        <td>{newsletterUser.user ? newsletterUser.user.name : newsletterUser.name}</td>
                        <td>{newsletterUser.user ? newsletterUser.user.surname : newsletterUser.surname}</td>
                        <td>{newsletterUser.user ? newsletterUser.user.email : newsletterUser.email}</td>
                        <td>{newsletterUser.user ? newsletterUser.user.birthday : newsletterUser.birthday}</td>
                        <td>{newsletterUser.user ? newsletterUser.user.address : newsletterUser.address}</td>
                        <td>{newsletterUser.user ? newsletterUser.user.city : newsletterUser.city}</td>
                        <td>{newsletterUser.user ? newsletterUser.user.state : newsletterUser.state}</td>
                        <td>{newsletterUser.user ? newsletterUser.user.postcode : newsletterUser.postcode}</td>
                        <td>{formatDate(newsletterUser.createdAt)}</td>
                        <td><button className='button' onClick={() => handleDelete(newsletterUser._id)}>Delete</button></td>
                      </tr>
                    ))}
                  </table>                
                </>
              )}

              <Footer />
            </>
          ) : (
            <NotFound />
          )
        ) : (
          <NotFound />
        )
      )}
    </div>
  );
}

// Define a function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export default AdminNewsletterUsers;