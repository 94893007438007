import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

import CalendarDateSelector from "../../../components/tour/CalendarDateSelector";

import '../../../styles/admin/adminElse.css';

function EditTour() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { tourId } = useParams(); // Get the tourId from URL parameters

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [tourData, setTourData] = useState({});
  const [loadingTourData, setLoadingTourData] = useState({});

  const [categoriesData, setCategoriesData] = useState({});
  const [loadingCategoriesData, setLoadingCategoriesData] = useState({});

  const [visible, setVisible] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [rate, setRate] = useState();
  const [price, setPrice] = useState();
  const [priceChild, setPriceChild] = useState();
  const [minimumAge, setMinimumAge] = useState();
  const [purchasable, setPurchasable] = useState(false);
  const [availableDates, setAvailableDates] = useState();
  const [availableSpaces, setAvailableSpaces] = useState();
  const [meetingPoint, setMeetingPoint] = useState();
  const [meetingTime, setMeetingTime] = useState();
  const [endingPoint, setEndingPoint] = useState();
  const [pricePrivate, setPricePrivate] = useState();
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [carBasePrice, setCarBasePrice] = useState(0);
  const [carGuidePrice, setCarGuidePrice] = useState(0);
  const [embedAMap, setEmbedAMap] = useState('');
  const [highlights, setHighlights] = useState('');
  const [duration, setDuration] = useState();
  const [whatIncluded, setWhatIncluded] = useState('');
  const [whatNotIncluded, setWhatNotIncluded] = useState('');
  const [whatToBring, setWhatToBring] = useState('');
  const [categories, setCategories] = useState([]);
  const [externalLink, setExternalLink] = useState();
  const [images, setImages] = useState();

  useEffect(() => {

    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    // Fetch Tour Data by category
    setLoadingTourData(true);
    axios.get(`https://api.mylondoncorner.com/tour/id/${tourId}`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setTourData(response.data);
      setLoadingTourData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTourData(false);
    });
  }, []);

  useEffect(() => {
    if(!loadingTourData)
    {
      setVisible(tourData.tours.visible.toString())
      setName(tourData.tours.name)
      setDescription(tourData.tours.description)
      setContent(tourData.tours.content)
      setTags(tourData.tours.tags)
      setRate(tourData.tours.rate)
      setPrice(tourData.tours.price)
      setPriceChild(tourData.tours.price_child)
      setMinimumAge(tourData.tours.minimumAge)
      setDuration(tourData.tours.duration)
      setPurchasable(tourData.tours.purchasable)
      setCategories(tourData.tours.category.map((category) => {return category._id}))
      setExternalLink(tourData.tours.external_link)
      if(tourData.tours.purchasable) {
        setAvailableDates(tourData.tours.available_dates.split(',').map(dateString => { const [day, month, year] = dateString.split('/'); return new Date(year, month - 1, day)}))
        setAvailableSpaces(tourData.tours.available_places)
        setMeetingPoint(tourData.tours.meeting_point)
        setMeetingTime(tourData.tours.meeting_time)
        setEndingPoint(tourData.tours.ending_point)
        setPricePrivate(tourData.tours.price_private)
        setLatitude(tourData.tours.latitude)
        setLongitude(tourData.tours.longitude)
        setCarBasePrice(tourData.tours.car_base_price)
        setCarGuidePrice(tourData.tours.car_price_guide)
        setEmbedAMap(tourData.tours.embed_a_map)
        setHighlights(tourData.tours.highlights)
        setWhatIncluded(tourData.tours.what_included)
        setWhatNotIncluded(tourData.tours.what_not_included)
        setWhatToBring(tourData.tours.what_to_bring)
      }
    }
  }, [tourData]);

  useEffect(() => {
    // Fetch Tour Data by category
    setLoadingCategoriesData(true);
    axios.get(`https://api.mylondoncorner.com/categories`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setCategoriesData(response.data);
      setLoadingCategoriesData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingCategoriesData(false);
    });
  }, []);

  useEffect(() => {
    console.error(images)
  }, [images])

  const handlePostSubmission = async () => {
    if (
      visible !== null &&
      name !== '' &&
      description !== '' &&
      tags !== '' &&
      rate !== null &&
      price !== null &&
      priceChild !== null &&
      categories.length !== 0 &&
      minimumAge !== null
    ) {
      const formData = new FormData();
      formData.append('tourId', tourId);
      formData.append('visible', visible);
      formData.append('name', name);
      formData.append('description', description);
      formData.append('content', content);
      formData.append('tags', tags);
      formData.append('rate', rate);
      formData.append('price', price);
      formData.append('priceChild', priceChild);
      formData.append('duration', duration);
      formData.append('minimumAge', minimumAge);
      formData.append('purchasable', purchasable);
      formData.append('latitude', latitude);
      formData.append('longitude', longitude);
      formData.append('categories[]', categories);
      formData.append('externalLink', externalLink);

      // Append each image to the formData
      if (images && images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          formData.append('images', images[i]);
        }
      }

      if(purchasable) {
        formData.append('availableDates', convertDatesToString(availableDates.toString()));
        formData.append('availableSpaces', availableSpaces);
        formData.append('meetingPoint', meetingPoint);
        formData.append('meetingTime', meetingTime);
        formData.append('endingPoint', endingPoint);
        formData.append('pricePrivate', pricePrivate);
        formData.append('carBasePrice', carBasePrice);
        formData.append('carGuidePrice', carGuidePrice);
        formData.append('embedAMap', embedAMap);
        formData.append('highlights', highlights);
        formData.append('whatIncluded', whatIncluded);
        formData.append('whatNotIncluded', whatNotIncluded);
        formData.append('whatToBring', whatToBring);
      }

      axios.post('https://api.mylondoncorner.com/tour/update', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-KEY': process.env.REACT_APP_API_KEY
          }
        })
        .then((response) => {
          if (response.status === 200) {
            navigate('/admin/tours');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      alert('Required fields must be typed in!');
    }
  };  

  const handleDateChange = (dates) => {
    setAvailableDates(dates);
  };

  return (
    <>
      {loadingUserData || loadingCategoriesData || loadingTourData ? (
        <div className="loading-animation"></div>
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

                <br /><br />

                <div className='admin-container'>

                  <h1>Edit Tour</h1>

                  <br /><br />

                  <p>Visible</p>
                  <select id="visible" name="visible" onChange={(e) => setVisible(e.target.value)}>
                    <option value={visible == 'true' ? '1' : '0'} selected>{visible}</option>
                    <option value="1">true</option>
                    <option value="0">false</option>
                  </select>

                  <br /><br />

                  <p>Name</p>
                  <input type='text' id="name" name="name" onChange={(e) => setName(e.target.value)} value={name} />

                  <br /><br />

                  <p>Description</p>
                  <textarea id="description" rows={5} name="description" onChange={(e) => setDescription(e.target.value)} value={description} />

                  <br /><br />

                  <p>Content (HTML)</p>
                  <textarea id="content" rows={15} name="content" onChange={(e) => setContent(e.target.value)} value={content} />

                  <br /><br />

                  <p>Tags</p>
                  <input type='text' id="tags" name="tags" onChange={(e) => setTags(e.target.value)} value={tags} />

                  <br /><br />

                  <p>Rate</p>
                  <select id="rate" name="rate" onChange={(e) => setRate(e.target.value)}>
                    <option value={rate} selected>{rate}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>

                  <br /><br />

                  <p>Price</p>
                  <input type='number' id="price" name="price" onChange={(e) => setPrice(e.target.value)} value={price} />

                  <br /><br />

                  <p>Price child</p>
                  <input type='number' id="priceChild" name="priceChild" onChange={(e) => setPriceChild(e.target.value)} value={priceChild} />

                  <br /><br />

                  <p>Duration</p>
                  <input type='text' id="duration" name="duration" onChange={(e) => setDuration(e.target.value)} value={duration} />

                  <br /><br />

                  <p>Minimum age</p>
                  <input type='number' id="minimumAge" name="minimumAge" onChange={(e) => setMinimumAge(e.target.value)} value={minimumAge} />

                  <br /><br />

                  {purchasable ? (
                    <>

                      {carBasePrice === undefined || carBasePrice === 0 &&
                        <>
                          <br />
                          <CalendarDateSelector alreadySelectedDates={availableDates} onDateChange={handleDateChange} />
                          <br /><br />
                        </>
                      }

                      <p>Available spaces</p>
                      <input type='number' id="availableSpaces" name="availableSpaces" onChange={(e) => setAvailableSpaces(e.target.value)} value={availableSpaces} />

                      <br /><br />

                      <p>Meeting point</p>
                      <input type='text' id="meetingPoint" name="meetingPoint" onChange={(e) => setMeetingPoint(e.target.value)} value={meetingPoint} />

                      <br /><br />

                      <p>Meeting time</p>
                      <input type='time' id="meetingTime" name="meetingTime" onChange={(e) => setMeetingTime(e.target.value)} value={meetingTime} />

                      <br /><br />

                      <p>Ending point</p>
                      <input type='text' id="endingPoint" name="endingPoint" onChange={(e) => setEndingPoint(e.target.value)} value={endingPoint} />

                      <br /><br />

                      <p>Car price (If car tour)</p>
                      <input type='number' id="carBasePrice" name="carBasePrice" onChange={(e) => setCarBasePrice(e.target.value)} value={carBasePrice} />
                      
                      <br /><br />

                      <p>Car Guide price (If car tour)</p>
                      <input type='number' id="carGuidePrice" name="carGuidePrice" onChange={(e) => setCarGuidePrice(e.target.value)} value={carGuidePrice} />
                      
                      <br /><br />

                      <p>Price private</p>
                      <input type='number' id="pricePrivate" name="pricePrivate" onChange={(e) => setPricePrivate(e.target.value)} value={pricePrivate} />
                      
                      <br /><br />

                      <p>Embed a map script</p>
                      <input type='text' id="embedAMap" name="embedAMap" onChange={(e) => setEmbedAMap(e.target.value)} value={embedAMap} />

                      <br /><br />

                      <p>Highlights •</p>
                      <textarea id="highlights" rows={6} name="highlights" onChange={(e) => setHighlights(e.target.value)} value={highlights} />

                      <br /><br />

                      <p>What's included •</p>
                      <textarea id="whatIncluded" rows={6} name="whatIncluded" onChange={(e) => setWhatIncluded(e.target.value)} value={whatIncluded} />

                      <br /><br />

                      <p>What's NOT included •</p>
                      <textarea id="whatNotIncluded" rows={6} name="whatNotIncluded" onChange={(e) => setWhatNotIncluded(e.target.value)} value={whatNotIncluded} />

                      <br /><br />

                      <p>What to bring •</p>
                      <textarea id="whatToBring" rows={6} name="whatToBring" onChange={(e) => setWhatToBring(e.target.value)} value={whatToBring} />

                      <br /><br />
                    </>

                  ) : null}

                  <p>Latitude</p>
                  <input type='text' id="latitude" name="latitude" onChange={(e) => setLatitude(e.target.value)} value={latitude} />

                  <br /><br />

                  <p>Longitude</p>
                  <input type='number' id="longitude" name="longitude" onChange={(e) => setLongitude(e.target.value)} value={longitude} />

                  <br /><br />

                  <p>Categories</p>
                  <select
                    id="categories"
                    name="categories"
                    multiple
                    onChange={(e) => {
                      const selectedCategoryIds = Array.from(e.target.selectedOptions, option => option.value);
                      setCategories(selectedCategoryIds);
                    }}
                  >
                    {tourData.tours.category.map(category => (
                      <option key={category._id} value={category._id} selected>
                        {category.name}
                      </option>
                    ))}
                    {categoriesData.categories.map(category => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>

                  {!purchasable &&
                    <>
                      <br /><br />
                      <p>External Link</p>
                      <input type='text' id="externalLink" name="externalLink" onChange={(e) => setExternalLink(e.target.value)} value={externalLink} /> 
                    </>
                  }

                  <br /><br />

                  <p>Cover images (1200x681)px</p>
                  <input type="file" name="images" onChange={(e) => setImages(e.target.files)} multiple />

                  <br /><br /><br />

                  <button className='button' onClick={handlePostSubmission}>Submit</button>

                </div>

              <Footer />
            </>
          ) : (
            navigate('/')
          )
        ) : (
          navigate('/')
        )
      )}
    </>
  );
}

function convertDatesToString(datesString) {
  const datesArray = datesString.split(',');
  const formattedDates = datesArray.map(dateString => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month index starts from 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  });
  return formattedDates.join(',');
}

export default EditTour;