import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../../styles/widgets/sliderImages.css';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

export default function SliderImages({ images }) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        {images.map((image) => (
          <SwiperSlide><img src={'https://api.mylondoncorner.com/' + image} /></SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}