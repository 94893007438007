import React from 'react';

function Image({ url, onClick, width, style: customStyle, className }) {
  const imgStyle = {
    width: width ? width : '100%',
    ...customStyle
  };

  return (
    <>
      <img src={url} style={imgStyle} onClick={onClick} className={className} />
    </>
  );
}

export default Image;