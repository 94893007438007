import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

// Styles
import '../../styles/tour/tourCalendarSelector.css';

const MyCalendar = ({ alreadySelectedDates, onDateChange }) => {
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    if(alreadySelectedDates)
      setSelectedDates(alreadySelectedDates)
    console.error(alreadySelectedDates)
  }, [])

  // Function to determine if a date is selectable
  const tileClassName = ({ date }) => {
    return selectedDates.some(selectedDate =>
      isSameDay(selectedDate, date)
    ) ? 'selected-date' : '';
  };

  const handleDateChange = (date) => {
    const dateIndex = selectedDates.findIndex(selectedDate =>
      isSameDay(selectedDate, date)
    );

    let newSelectedDates = [];

    if (dateIndex === -1) {
      // If the date is not already selected, add it to the selected dates array
      newSelectedDates = [...selectedDates, date];
    } else {
      // If the date is already selected, remove it from the selected dates array
      newSelectedDates = selectedDates.filter((_, index) => index !== dateIndex);
    }

    setSelectedDates(newSelectedDates);
    onDateChange(newSelectedDates); // Call the callback prop with the new selected dates
  };

  // Helper function to compare if two dates are the same day
  const isSameDay = (dateA, dateB) => {
    return (
      dateA.getDate() === dateB.getDate() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getFullYear() === dateB.getFullYear()
    );
  };

  return (
    <>
      <div className="calendar-wrapper">
        <Calendar
          locale="it-IT"
          className="custom-calendar-selector"
          selectRange={false}
          value={selectedDates}
          onChange={handleDateChange}
          tileClassName={tileClassName}
        />
      </div>
    </>
  );
};

export default MyCalendar;
