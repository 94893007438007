import React, { useState } from "react";

// Example usage in your component:
const Message = ({ message, type }) => {
  const [showMessage, setShowMessage] = useState(true);

  const alert = {
    padding: '20px',
    backgroundColor: type === 'error' ? '#e74c3c' : type === 'good' ? '2ecc71' : '#3498db',
    color: 'white',
    width: '60%',
    margin: '2% auto',
    borderRadius: '10px'
  };

  const closebtn = {
    marginLeft: '15px',
    color: 'white',
    fontWeight: 'bold',
    float: 'right',
    fontSize: '22px',
    lineHeight: '20px',
    cursor: 'pointer',
    transition: '0.3s'
  };

  const handleClose = () => {
    // Set showMessage state to false to hide the message
    setShowMessage(false);
  };

  return (
    <>
      {showMessage && (
        <div style={alert}>
          <span style={closebtn} onClick={handleClose}>&times;</span> 
          <strong>{type === 'error' ? 'Errore!' : null}</strong> {message}
        </div>
      )}
      <br />
    </>
  );
};

export default Message;
