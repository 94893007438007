import React from 'react';
import Modal from 'react-modal';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '400px',
    width: '100%',
  },
};

const FeedbackPopup = ({ isOpen, message, onClose, onConfirm, confirmButtonText }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Feedback Popup"
      style={modalStyles}
      ariaHideApp={false} // to prevent accessibility errors
    >
      <div>
        <h2>{message}</h2>
        <br />
        <button className='button' onClick={onClose}>Chiudi</button>
      </div>
    </Modal>
  );
};

export default FeedbackPopup;