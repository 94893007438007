import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

export default function App({ categories, type }) {
  const navigate = useNavigate();

  const handleClick = (category) => {
    if(type === 'tickets') {
      navigate(`/tickets/category/${category.name}`);
    } else if(type === 'tours') {
      navigate(`/tours/category/${category.name}`);
    }
  }

  return (
    <>
      <style>
        {`
          .swiper-slide img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            border: solid 1px #ccc;
          }
        `}
      </style>
      <Swiper
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2.5,
            spaceBetween: 30,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 6.5,
            spaceBetween: 30,
          },
        }}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
      >
        {categories.map((category) => (
          <SwiperSlide key={category._id} style={{ cursor: 'pointer' }} onClick={() => handleClick(category)}>
            <div style={{ width: '100%', paddingTop: '100%', position: 'relative' }}>
              <img
                src={'https://api.mylondoncorner.com/' + category.image_path}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px', border: 'solid 1px #ccc' }}
                alt="Image"
              />
            </div>
            <h4 style={{ textAlign: 'left', marginTop: '2%', fontSize: '14px' }}>{category.name}</h4>
            <br/><br/>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
