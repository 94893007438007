import { React, useState } from 'react';
import { auth } from '../../config/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

// Components
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';
import InputText from '../../components/inputs/InputText';

// Styles
import '../../styles/signup.css';

function ForgotPassword() {

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");

  const handlePasswordReset = async () => {
    try {
      if(email === confirmEmail && email != '') {
        await sendPasswordResetEmail(auth, email);
        alert("Email di reset della password inviata. Controlla la tua casella di posta in arrivo.");
      } else {
        alert("Le Email inserite non combaciano.");
      }
    } catch (error) {
      console.error("Errore nell'invio dell'email di reset della password:", error.message);
      alert("Errore nell'invio dell'email di reset della password. Si prega di riprovare più tardi.");
    }
  };

  return (
    <>
      <Navbar />
      <div className="row">
        <br></br>
        <div class="signup-box">
          <h1>Ripristina Password</h1>
          <br />
          <InputText type='email' label='E-mail' id='email' name='email' required='true' width='100%' onChange={(e) => setEmail(e.target.value)} />
          <br />
          <InputText type='email' label='Conferma E-mail' id='email' name='email' required='true' width='100%' onChange={(e) => setConfirmEmail(e.target.value)} />   
          <br /><br />
          <button className='button' onClick={handlePasswordReset}>Ripristina</button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ForgotPassword;