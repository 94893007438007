import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

export default function AdminTrips() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [tripsData, setTripsData] = useState({});
  const [loadingTripsData, setLoadingTripsData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    setLoadingTripsData(true);
    axios.get(`https://api.mylondoncorner.com/trips`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setTripsData(response.data);
      setLoadingTripsData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTripsData(false);
    });
  }, []);

  const handleDelete = async (tripId) => {
    if (window.confirm("Are you sure you want to delete this trip?")) {
      try {
        await axios.delete(`https://api.mylondoncorner.com/trip/delete/${tripId}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY
          }
        });
        // Remove the deleted trip from the state
        setTripsData(tripsData.trips.filter(trip => trip._id !== tripId));
      } catch (error) {
        console.error('Error deleting trip:', error);
        alert('Failed to delete trip. Please try again.');
      }
    }
  };

  return (
    <div className="table-container">
      {loadingUserData ? (
        <div className="loading-animation"></div>
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

              <br />

              <button className='button' onClick={() => navigate('/admin/trip/new')}>New</button>

              {loadingTripsData ? (
                <div className="loading-animation"></div>
              ) : (
                <>
                  <br />
                  <table>
                    <tr>
                      <th>Image</th>
                      <th>Status</th>
                      <th>Visibility</th>
                      <th>Name</th>
                      <th>Price Adult</th>
                      <th>Price Child</th>
                      <th>Price Newborn</th>
                      <th>Categories</th>
                      <th>Last update</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                    {tripsData.trips.map((trip, index) => (
                      <tr key={index}>
                        <a href={ trip.external_link ? trip.external_link : '/trip/' + trip.slug }>
                          <td><img src={'https://api.mylondoncorner.com/' + trip.images_paths[0]} style={{ width: '80px', cursor: 'pointer' }} /></td>
                        </a>
                        <td>{trip.status}</td>
                        <td>{trip.visible.toString()}</td>
                        <td>{trip.name}</td>
                        <td>{trip.price}</td>
                        <td>{trip.price_child}</td>
                        <td>{trip.price_newborn}</td>
                        <td>
                          {trip.category.map((category, index) => (
                            <p key={index}>{category.name}</p>
                          ))}
                        </td>
                        <td>{formatDate(trip.updatedAt)}</td>
                        <td><button className='button' onClick={() => navigate('/admin/trip/edit/' + trip._id)}>Edit</button></td>
                        <td><button className='button' onClick={() => handleDelete(trip._id)}>Delete</button></td>
                      </tr>
                    ))}
                  </table>                
                </>
              )}

              <Footer />
            </>
          ) : (
            navigate('/')
          )
        ) : (
          navigate('/')
        )
      )}
    </div>
  );
}

// Define a function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}