import React from 'react';

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';

function PrivacyPolicy() {
  return (
    <>
      <Navbar />

        <br /><br />

        <div className='home-container' style={{ textAlign: 'left' }}>

          <h1 style={{ textAlign: 'center' }}>Privacy Policy</h1>

          <br /><br />

          <p>
              mylondoncorner, è l'agenzia di crescita e destinazione per Londra. 
              La nostra missione è semplificare la visita della città.
          </p>

          <br />

          <p>
              Questa policy stabilisce le basi su cui tutti i dati personali che raccogliamo da te, 
              che ci fornisci o che potremmo raccogliere da altre fonti verranno elaborati da noi. 
              Ciò include i vari modi in cui interagisci con noi, sia attraverso uno dei nostri numerosi siti Web, 
              ad esempio mylondoncorner.com ("siti") e le nostre applicazioni mobili, ad esempio l'applicazione mobile mylondoncorner ("app"). 
              Insieme, i nostri siti e le nostre applicazioni sono conosciuti come le nostre "piattaforme". 
              Potremmo anche interagire con te attraverso i vari servizi che forniamo, 
              ad esempio l'organizzazione di "tour" ("servizi").
          </p>

          <br />

          <p>   
              Sulle nostre piattaforme possono comparire collegamenti a siti di terzi. 
              Questi siti di terze parti non sono di nostra responsabilità, anche se facciamo del nostro meglio per sceglierli con cura. 
              È necessario leggere e soddisfare le politiche sulla privacy di tali terze parti prima di utilizzare tali siti collegati.
          </p>

          <br /><br />

          <h2>Quali informazioni raccogliamo da te?</h2>

          <br />

          <h3>1. Informazioni che ci dai. Si tratta di informazioni su di te che puoi fornirci tramite:</h3>

          <br />

          <p>• compilazione di moduli su una qualsiasi delle nostre piattaforme;</p>
          <br />
          <p>• come parte dell'utilizzo dei nostri servizi;</p>
          <br />
          <p>• corrispondendo con noi per telefono, e-mail, chat online o altro;</p>
          <br />
          <p>• scaricare un'app e/o registrarsi su una delle nostre piattaforme;</p>
          <br />
          <p>• Interagire con le nostre piattaforme (ad esempio, abbonarsi a qualsiasi servizio, effettuare un acquisto in-app o partecipare a un concorso);</p>
          <br />
          <p>• Condivisione di informazioni sui social media dalle nostre piattaforme;</p>
          <br />
          <p>• Segnalare un problema o un problema con una delle nostre piattaforme.</p>

          <br />

          <h3>2. Informazioni che raccogliamo su di te. In relazione a ciascuna delle tue visite ai nostri siti e/o alle app, potremmo raccogliere automaticamente le seguenti informazioni:</h3>

          <br />

          <p>• informazioni tecniche, compreso l'indirizzo IP (Internet Protocol) utilizzato per connettere il computer a Internet, il tipo di dispositivo mobile utilizzato, un identificatore univoco del dispositivo (ad esempio il numero IMEI del dispositivo, l'indirizzo MAC dell'interfaccia di rete wireless del dispositivo o il numero di cellulare utilizzato dal dispositivo), informazioni sulla rete mobile, sistema operativo mobile, informazioni di accesso, tipo e versione di browser in uso, impostazione del fuso orario, tipi e versioni di plug-in del browser, sistema operativo e piattaforma;</p>
          <br />
          <p>• informazioni sulla tua visita alle nostre piattaforme, incluso il flusso di clic completo dei localizzatori di risorse uniformi (URL) verso, attraverso e dalle nostre piattaforme (compresi data e ora); pagine che hai visualizzato o informazioni che hai cercato; tempi di risposta della pagina, errori di download, durata delle visite a determinate pagine, informazioni sull'interazione della pagina (come scorrimento, clic e passaggi del mouse) e metodi utilizzati per allontanarsi dalla pagina e qualsiasi numero di telefono utilizzato per chiamare il nostro numero di servizio clienti ;</p>
          <br />
          <p>• informazioni memorizzate sul tuo dispositivo mobile, che possono includere informazioni di contatto, elenchi di amici, informazioni di accesso, foto, video o altri contenuti digitali, check-in;</p>
          <br />
          <p>• i dettagli del tuo utilizzo e delle visite a una qualsiasi delle nostre piattaforme inclusi, a titolo esemplificativo, dati sul traffico, weblog e altri dati di comunicazione, indipendentemente dal fatto che ciò sia necessario per i nostri scopi di fatturazione o meno e le risorse a cui accedi;</p>
          <br />
          <p>• utilizziamo anche la tecnologia GPS in alcune app per determinare la tua posizione. Alcuni dei nostri servizi abilitati alla posizione all'interno delle app richiedono i tuoi dati personali affinché la funzione funzioni. Se desideri utilizzare la particolare funzionalità, ti verrà chiesto di acconsentire all'utilizzo dei tuoi dati per questo scopo. Puoi revocare il tuo consenso in qualsiasi momento modificando le impostazioni dell'applicazione sul tuo dispositivo mobile;</p>
          <br />
          <p>• quando installi o disinstalli un servizio contenente un numero di applicazione univoco o quando tale servizio cerca aggiornamenti automatici, quel numero e le informazioni sulla tua installazione, ad esempio il tipo di sistema operativo, potrebbero esserci inviati.</p>

          <br />

          <h3>3. Informazioni che riceviamo da altre fonti. Potremmo ricevere informazioni su di te se utilizzi altri siti Web che gestiamo o altri servizi che forniamo. Lavoriamo anche con terze parti (inclusi, ad esempio, analisi elettroniche, partner commerciali, subappaltatori di servizi tecnici, di pagamento e di consegna, reti pubblicitarie, fornitori di analisi, fornitori di informazioni di ricerca, agenzie di riferimento del credito) e potremmo ricevere informazioni su di te da loro.</h3>

          <br />

          <h3>4. I tipi di informazioni personali che elaboriamo. Le informazioni personali che raccogliamo possono includere:</h3>

          <br />

          <p>• il tuo nome completo;</p>
          <br />
          <p>• indirizzo postale;</p>
          <br />
          <p>• datore di lavoro e titolo di lavoro;</p>
          <br />
          <p>• indirizzo e-mail;</p>
          <br />
          <p>• registrazioni della tua corrispondenza con noi;</p>
          <br />
          <p>• numeri di telefono;</p>
          <br />
          <p>• nome utente e password;</p>
          <br />
          <p>• foto e video;</p>
          <br />
          <p>• nomi di account di social media;</p>
          <br />
          <p>• dettagli di pagamento;</p>
          <br />
          <p>• e qualsiasi altra informazione che potresti condividere con noi o entrare in una qualsiasi delle nostre piattaforme.</p>

          <br />

          <h3>5. Occasionalmente raccogliamo anche dati personali sensibili su di te, ad esempio informazioni sulla salute. Raccogliamo questi dati solo se abbiamo il tuo esplicito consenso, a meno che non ci sia consentito farlo in altre circostanze ai sensi della legge sulla protezione dei dati. Ad esempio, potremmo registrare che una persona ha particolari requisiti di accesso per garantire che possa partecipare a un evento.</h3>

          <br />

          <h3>6. Possiamo combinare le informazioni che ci fornisci e le informazioni che raccogliamo su di te. Possiamo utilizzare queste informazioni e le informazioni combinate per gli scopi sopra indicati (a seconda dei tipi di informazioni che riceviamo).</h3>

          <br />

          <h3>7. I dati personali possono essere legalmente raccolti e utilizzati se è necessario per un legittimo interesse dell'organizzazione che utilizza i dati, purché il loro utilizzo sia corretto e non pregiudichi i diritti dell'interessato. Quando utilizziamo le tue informazioni personali, valuteremo sempre se è giusto ed equilibrato farlo e se rientra nelle tue ragionevoli aspettative. Bilanceremo i tuoi diritti e i nostri interessi legittimi per assicurarci di utilizzare le tue informazioni personali in modi che non siano indebitamente invadenti o ingiusti. I nostri interessi legittimi includono:</h3>

          <br />

          <p>• Promozione dei nostri scopi, incluso lo sviluppo di nuove offerte e servizi (sia in modo indipendente che con i nostri partner) per promuovere Londra a visitatori, studenti e aziende;</p>
          <br />
          <p>• Amministrazione e gestione operativa, inclusa la risposta alle richieste sollecitate, la fornitura delle informazioni richieste, la ricerca, la gestione degli eventi, l'amministrazione delle nostre risorse e strumenti.</p>
          <br />
          <p>• Collaborare con terze parti, inclusa la collaborazione con organizzazioni partner per sviluppare nuovi programmi e risorse che promuovano i nostri scopi.</p>

          <br />

          <h3>8. Se desideri maggiori informazioni sui nostri usi degli interessi legittimi o per modificare il nostro utilizzo dei tuoi dati personali in questo modo, ti preghiamo di contattarci utilizzando i dettagli nella sezione "Contattaci" di seguito.</h3>

          <br />

          <h3>9. Potremmo divulgare le tue informazioni personali a terzi:</h3>

          <br />

          <p>• nel caso in cui trasferiamo, vendiamo o acquistiamo attività o beni, nel qual caso potremmo divulgare i tuoi dati personali al potenziale venditore o acquirente di tali attività o beni; e/o</p>
          <br />
          <p>• se abbiamo l'obbligo di divulgare o condividere i tuoi dati personali al fine di adempiere a qualsiasi obbligo legale o per far rispettare o applicare i nostri termini di utilizzo e altri accordi; o per proteggere i diritti, la proprietà o la nostra sicurezza e/o, i nostri clienti o altri. Ciò include lo scambio di informazioni con altre società e organizzazioni ai fini della protezione dalle frodi e della riduzione del rischio di credito.</p>

          <br />

          <h3>10. Potremmo consentire al nostro personale, consulenti e/o fornitori esterni (incaricati del trattamento dei dati) che agiscono per nostro conto di accedere e utilizzare le tue informazioni per gli scopi per i quali ci hai fornito (ad esempio per analizzare i dati ed elaborare i pagamenti). Forniamo loro solo le informazioni di cui hanno bisogno per fornire il servizio pertinente e ci assicuriamo di adottare misure per garantire che elaborino i dati personali in modo sicuro.</h3>

          <br /><br />

          <h2>Come usiamo le tue informazioni personali?</h2>

          <br />

          <h3>Utilizziamo le informazioni detenute su di te nei seguenti modi:</h3>

          <br />

          <p>• per comunicare con te in risposta a un'azione specifica da te eseguita sulle nostre piattaforme, ad es. quando prenoti un hotel, acquisti un biglietto, prenoti un tavolo, lasci una recensione, partecipi a un concorso, ecc;</p>
          <br />
          <p>• per fornirti supporto nell'utilizzo delle nostre piattaforme e per fornirti le informazioni, i prodotti e i servizi che ci richiedi;</p>
          <br />
          <p>• per fornirti informazioni su uno dei nostri numerosi eventi, ad esempio le nostre conferenze sul commercio e gli affari a Londra;</p>
          <br />
          <p>• per fornirti informazioni su altri servizi che offriamo simili a quelli di cui hai già chiesto informazioni, ad esempio newsletter, concorsi e offerte;</p>
          <br />
          <p>• per fornirti, o consentire a terzi selezionati di fornirti, informazioni su beni o servizi che riteniamo possano interessarti. Se sei un abbonato esistente, ti contatteremo solo per via elettronica (e-mail, SMS o WhatsApp) con informazioni su servizi simili a quelli che offriamo sulle nostre piattaforme. Se consentiamo a terzi selezionati di utilizzare i tuoi dati, noi (o loro) ti contatteremo per via elettronica solo se hai acconsentito a ciò. Se sei un contatto commerciale, potremmo contattarti con informazioni su Londra e sulle opportunità che noi (e i nostri partner commerciali) possiamo offrirti. Se non desideri che utilizziamo i tuoi dati in questo modo, o per trasmettere i tuoi dati a terzi per scopi di marketing, ti preghiamo di barrare l'apposita casella situata nel modulo in cui raccogliamo i tuoi dati;</p>
          <br />
          <p>• se sei interessato a sviluppare la tua attività a Londra, potremmo contattarti per fornirti informazioni che riteniamo possano interessarti e/o contatti commerciali che potrebbero aiutarti;</p>
          <br />
          <p>• per inviarti notizie rilevanti per i tuoi interessi;</p>
          <br />
          <p>• per informarti sulle modifiche ai nostri servizi;</p>
          <br />
          <p>• per garantire che i contenuti delle nostre piattaforme siano presentati nel modo più efficace per te e per il tuo computer e/o dispositivo mobile;</p>
          <br />
          <p>• per amministrare le nostre piattaforme per le operazioni interne, inclusa la risoluzione dei problemi, l'analisi dei dati, i test, la ricerca, le statistiche e i sondaggi;</p>
          <br />
          <p>• migliorare le nostre piattaforme per garantire che i contenuti siano presentati nel modo più efficace per te e per il tuo computer e/o dispositivo mobile;</p>
          <br />
          <p>• per aiutarci a migliorare e personalizzare i nostri servizi, sia sulle nostre piattaforme che su siti di terzi;</p>
          <br />
          <p>• per consentirti di partecipare alle funzionalità interattive dei nostri servizi, quando scegli di farlo;</p>
          <br />
          <p>• come parte dei nostri sforzi per mantenere le nostre piattaforme sicure e protette;</p>
          <br />
          <p>• per misurare o comprendere l'efficacia della pubblicità che serviamo a te e ad altri e per fornirti pubblicità pertinente;</p>
          <br />
          <p>• per fornire suggerimenti e raccomandazioni a te e ad altri utenti delle nostre piattaforme su beni o servizi che potrebbero interessarti o loro;</p>
          <br />
          <p>• per ottemperare alle leggi e ai regolamenti applicabili e alle richieste degli enti statutari; e</p>
          <br />
          <p>• laddove sia altrimenti necessario per i nostri legittimi interessi (o per gli interessi di terzi) e i tuoi interessi e diritti fondamentali non prevalgono su tali interessi.</p>

          <br />

          <p>Utilizzeremo i tuoi dati personali solo per gli scopi per i quali li abbiamo raccolti, a meno che non riteniamo ragionevolmente di doverli utilizzare per un altro motivo e tale motivo è compatibile con lo scopo originale. Se desideri ottenere una spiegazione su come il trattamento per la nuova finalità sia compatibile con la finalità originaria, contattaci.</p>

          <br /><br />

          <h2>La nostra base giuridica per il trattamento dei dati</h2>

          <br />

          <h3>Le organizzazioni che raccolgono e utilizzano dati personali devono disporre di una base legale per farlo ai sensi della legge sulla protezione dei dati e siamo tenuti a farti conoscere la base legale su cui facciamo affidamento per elaborare i tuoi dati personali. Facciamo affidamento sulle seguenti basi legali:</h3>

          <br />

          <p>• Il tuo consenso a utilizzare i tuoi dati per uno scopo particolare (ad esempio per inviarti marketing diretto tramite e-mail o per raccogliere dati personali sensibili);</p>
          <br />
          <p>• Laddove l'utilizzo dei dati sia nel nostro "legittimo interesse" (vedi sotto per maggiori informazioni);</p>
          <br />
          <p>• Laddove abbiamo bisogno di elaborare i tuoi dati al fine di adempiere ai nostri obblighi ai sensi di un contratto che abbiamo stipulato con te (ad esempio per iscriverti a un evento o fornirti l'accesso a risorse); e</p>
          <br />
          <p>• Per elaborare i dati laddove siamo obbligati dalla legge a farlo.</p>

          <br /><br />

          <h2>Condivideremo le tue informazioni con altri?</h2>

          <br />

          <p>• Potremmo condividere le tue informazioni personali con qualsiasi membro del nostro gruppo, che include le società del nostro gruppo sia nel Regno Unito che al di fuori del Regno Unito.</p>
          <br />
          <p>• Potremmo condividere le tue informazioni con terze parti selezionate, tra cui:</p>
          <br />
          <p>• partner commerciali, fornitori e subappaltatori per l'esecuzione di qualsiasi contratto che stipuliamo con loro o con voi;</p>
          <br />
          <p>• potremmo (con il tuo consenso, ove appropriato) trasmettere i tuoi dati a terzi, inclusi i nostri partner, per fornirti informazioni e offerte che potrebbero interessarti;</p>
          <br />
          <p>• inserzionisti e reti pubblicitarie che richiedono i dati per selezionare e offrire annunci pertinenti a te e ad altri. Non divulghiamo informazioni su individui identificabili ai nostri inserzionisti, ma possiamo fornire loro informazioni aggregate sui nostri utenti (ad esempio, potremmo informarli che 500 uomini di età inferiore ai 30 anni hanno cliccato sul loro annuncio in un dato giorno). Potremmo anche utilizzare tali informazioni aggregate per aiutare gli inserzionisti a raggiungere il tipo di pubblico a cui desiderano rivolgersi (ad esempio, le donne nel sud di Londra). Potremmo utilizzare i dati personali che abbiamo raccolto da te per consentirci di soddisfare i desideri dei nostri inserzionisti mostrando la loro pubblicità a quel pubblico di destinazione; e</p>
          <br />
          <p>• fornitori di analisi e motori di ricerca che ci assistono nel miglioramento e nell'ottimizzazione delle nostre piattaforme.</p>

          <br /><br />

          <h2>Come proteggiamo la sicurezza dei dati personali?</h2>

          <br />

          <p>• Abbiamo messo in atto misure di sicurezza adeguate per impedire che le tue informazioni personali vengano perse accidentalmente, utilizzate o accessibili in modo non autorizzato, alterate o divulgate.</p>
          <br />
          <p>• Sebbene faremo tutti gli sforzi ragionevoli per salvaguardare i tuoi dati personali, riconosci che l'uso di Internet non è del tutto sicuro e per questo motivo non possiamo garantire la sicurezza o l'integrità dei dati personali trasferiti tramite Internet. Se hai dubbi particolari sulle tue informazioni, ti preghiamo di contattarci.</p>
          <br />
          <p>• Abbiamo messo in atto procedure per affrontare qualsiasi sospetta violazione della sicurezza dei dati e informeremo te e qualsiasi autorità di regolamentazione applicabile di una sospetta violazione laddove siamo legalmente obbligati a farlo.</p>
          <br />
          <p>• Le informazioni che raccogliamo da te possono essere trasferite, elaborate e/o archiviate in una destinazione al di fuori del Regno Unito. Se inviamo i tuoi dati personali al di fuori del Regno Unito, garantiremo un livello di protezione simile, assicurandoci di trasferire i tuoi dati personali solo in paesi che sono stati ritenuti fornire un livello adeguato di protezione dei dati personali.</p>

          <br /><br />

          <h2>Per quanto tempo conserviamo i tuoi dati?</h2>

          <br />

          <p>• Conserveremo le tue informazioni personali solo per il tempo necessario a soddisfare gli scopi per cui le abbiamo raccolte, anche allo scopo di soddisfare eventuali requisiti legali, contabili o di segnalazione. Per determinare il periodo di conservazione appropriato per i dati personali, prendiamo in considerazione la quantità, la natura e la sensibilità dei dati personali, il potenziale rischio di danno derivante dall'uso o dalla divulgazione non autorizzati dei tuoi dati personali, le finalità per le quali trattiamo i tuoi dati personali e se possiamo raggiungere tali scopi attraverso altri mezzi e i requisiti legali applicabili.</p>
          <br />
          <p>• In alcune circostanze potremmo rendere anonime le tue informazioni personali in modo che non possano più essere associate a te, nel qual caso potremmo utilizzare tali informazioni senza ulteriore preavviso. Una volta che non avrai più una relazione con noi, conserveremo e distruggeremo in modo sicuro le tue informazioni personali in conformità con le leggi e i regolamenti applicabili.</p>
          <br />
          <p>• Se richiedi di non ricevere ulteriori contatti da parte nostra, conserveremo alcune informazioni di base su di te nel nostro elenco di soppressione per evitare di inviarti materiali indesiderati in futuro.</p>

          <br /><br />

          <h2>I tuoi diritti alla privacy</h2>

          <br />

          <h3>Hai una serie di diritti ai sensi della legislazione sulla protezione dei dati. Questi includono:</h3>

          <br />

          <p>• <b>Diritto di accesso</b><br />
          Hai il diritto di sapere quali informazioni conserviamo su di te e di chiedere, per iscritto, di vedere i tuoi registri. Ti forniremo i dettagli dei record in nostro possesso il prima possibile e al massimo entro un mese, a meno che la richiesta non sia complessa. Potremmo richiedere una prova di identità prima di poter rilasciare i dati. Si prega di utilizzare i dettagli nella sezione "Contattaci" di seguito se si desidera esercitare questo diritto.</p>
          <br />
          <p>• <b>Diritto ad essere informato</b><br />
          Hai il diritto di essere informato su come verranno utilizzati i tuoi dati personali. Questa politica, così come qualsiasi informazione aggiuntiva o avviso che ti viene fornito al momento in cui hai fornito i tuoi dati, o in altro modo, ha lo scopo di fornirti queste informazioni.</p>
          <br />
          <p>• <b>Diritto di revocare il consenso</b><br />
          Laddove trattiamo i tuoi dati sulla base del tuo consenso (ad esempio, per inviarti messaggi di marketing o e-mail) puoi revocare tale consenso in qualsiasi momento. Per fare ciò, o per discutere ulteriormente di questo diritto con noi, contattaci utilizzando i dettagli nella sezione "Contattaci" di seguito.</p>
          <br />
          <p>• <b>Diritto di opposizione</b><br />
          Hai anche il diritto di opporti al trattamento dei dati da parte nostra se facciamo affidamento sul fatto che ciò sia nel nostro legittimo interesse (ad esempio, inviarti marketing diretto per posta). Per fare ciò, o per discutere ulteriormente di questo diritto con noi, contattaci utilizzando i dettagli nella sezione "Contattaci" di seguito.</p>
          <br />
          <p>• <b>Diritto di limitare l'elaborazione</b><br />
          In determinate situazioni hai il diritto di chiedere la limitazione del trattamento dei tuoi dati personali a causa di un disaccordo sulla loro accuratezza o utilizzo legittimo.</p>
          <br />
          <p>• <b>Diritto di cancellazione</b><br />
          In alcuni casi, hai il diritto all'oblio (ovvero alla cancellazione dei tuoi dati personali dal nostro database). Laddove tu abbia richiesto di non inviarti materiale di marketing, dovremo conservare alcune informazioni limitate per assicurarci di non essere contattato in futuro.</p>
          <br />
          <p>• <b>Diritto di rettifica</b><br />
          Se ritieni che i nostri registri siano imprecisi, hai il diritto di richiedere l'aggiornamento di tali registri che ti riguardano. Per aggiornare i tuoi record, ti preghiamo di contattarci utilizzando i dettagli nella sezione "Contattaci" di seguito.</p>
          <br />
          <p>• <b>Diritto alla portabilità dei dati</b><br />
          Laddove trattiamo i tuoi dati personali perché ci hai dato il tuo consenso a farlo, hai il diritto di richiedere che i dati vengano trasferiti da un fornitore di servizi a un altro.</p>
          <br />
          <p>• <b>Denunce, contestazioni</b><br />
          Se non sei soddisfatto del modo in cui abbiamo gestito le tue informazioni personali, ti preghiamo di contattarci utilizzando i dettagli di seguito. Hai anche il diritto di presentare un reclamo all'Ufficio del Commissario per le informazioni - https://ico.org.uk/.</p>

          <br /><br />

          <h2>Come ti faremo sapere delle modifiche alla nostra politica sulla privacy?</h2>

          <br />

          <p>Potremmo aggiornare questa politica di tanto in tanto senza preavviso, quindi ti preghiamo di controllarla regolarmente. Cercheremo comunque di portare alla vostra attenzione eventuali modifiche significative. L'informativa sulla privacy è stata aggiornata l'ultima volta il 10 marzo 2023.</p>

          <br /><br />

          <h2>Come contattarci?</h2>

          <br />

          <p>• Domande, commenti e richieste in merito a questa policy sulla privacy sono i benvenuti e devono essere indirizzati a <a href="mailto:info@mylondoncorner.com">info@mylondoncorner.com</a>.</p>

        </div>

      <Footer />
    </>
  )
}

export default PrivacyPolicy;