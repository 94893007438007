import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// Icons
import { logoWhatsapp, logoInstagram, logoFacebook, logoTiktok } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { FaStar } from 'react-icons/fa';

// Components
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';
import NotFound from '../../components/NotFound';
import NewsletterPopup from '../../components/widgets/NewsletterPopup';

// Styles
import '../../styles/post/post.css';
import '../../styles/tables.css';
import '../../styles/inputs.css';

function Post() {
  const navigate = useNavigate();

  const { slug } = useParams(); // Get the slug from URL parameters

  const [postData, setPostData] = useState([]);
  const [loadingPost, setLoadingPost] = useState(true);

  useEffect(() => {
    setLoadingPost(true);
    axios.get(`https://api.mylondoncorner.com/post/${slug}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      })
      .then((response) => {
        setPostData(response.data);
        setLoadingPost(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoadingPost(false);
      });
  }, [slug]);

  return (
    <>
      {loadingPost ? (
        <>
          <Navbar />
          <div className="loading-animation"></div>
          <Footer />
        </>
      ) : (
        postData.post != null ? (
          <>
            <Helmet prioritizeSeoTags>
              <title>{postData.post.title}</title>‍
              <meta name="description" content={postData.post.content.replace(/<[^>]+>/g, '').slice(0, 150)} />
              <link rel="canonical" href={process.env.REACT_APP_BASE_URL + "/post/" + postData.post.slug} />
              <meta name="twitter:card" content={'https://api.mylondoncorner.com/' + postData.post.image_path} />        
              <meta name="twitter:site" content="@mylondoncorner" />        
              <meta name="twitter:creator" content="@mylondoncorner" />        
              <meta name="twitter:title" content={postData.post.title} />        
              <meta name="twitter:description" content="Benvenut* a Londra!" />        
              <meta name="twitter:image" content={'https://api.mylondoncorner.com/' + postData.post.image_path} />        
              <meta property="og:title" content={postData.post.title} />        
              <meta property="og:description" content={postData.post.content.replace(/<[^>]+>/g, '').slice(0, 150)} />
              <meta property="og:image" content={'https://api.mylondoncorner.com/' + postData.post.image_path} />
              <meta property="og:url" content={process.env.REACT_APP_BASE_URL + "/post/" + postData.post.slug} />
              <meta property="og:site_name" content="Il tuo viaggio a Londra! - mylondoncorner.com" />
              <meta property="og:locale" content="it_IT" />
              <meta property="og:type" content="website" />

              <meta name="keywords" content={postData.post.tags} />
            </Helmet>

            <Navbar />

            <div className='post-container'>
              <div className='row'>
                <br /><br />
                <div className='post-left-column'>
                  <h1 className='post-centered-text'>{postData.post.title}</h1>
                  <br /><br />
                  <img src={'https://api.mylondoncorner.com/' + postData.post.image_path} className='post-header-image' alt="Image" />
                  <br /><br />
                  <div dangerouslySetInnerHTML={{ __html: postData.post.content }} />
                  <br />
                  <br />
                  <hr />
                  <br />
                  <br />
                  <div className='post-social-links'>
                    <h2>I nostri social</h2>
                    <br />
                    <a href='https://www.instagram.com/mylondoncorner/'><IonIcon icon={logoInstagram} /></a>
                    <a href='https://www.facebook.com/mylondoncornerpage'><IonIcon icon={logoFacebook} /></a>
                    <a href='https://wa.me/7853545887?text=Ciao!%20Voglio%20organizzare%20un%20viaggio%20a%20Londra.'><IonIcon icon={logoWhatsapp} /></a>
                    <a href='https://www.tiktok.com/@mylondoncorner/'><IonIcon icon={logoTiktok} /></a>
                    <br />
                    <br />
                    <br />
                    <br />
                    <h2>Articoli correlati</h2>
                    <br/>
                    <a href={`/post/${postData.relatedPosts[0].slug}`} className='a-not-decorated'>
                      <div className='post-correlated'>
                        <img src={`https://api.mylondoncorner.com/${postData.relatedPosts[0].image_path}`} />
                        <h3>{postData.relatedPosts[0].title}</h3>
                        <p>{console.log(postData.relatedPosts[0])}</p>
                      </div>
                    </a>
                    <br />
                    <a href={`/post/${postData.relatedPosts[1].slug}`} className='a-not-decorated'>
                      <div className='post-correlated'>
                        <img src={`https://api.mylondoncorner.com/${postData.relatedPosts[1].image_path}`} />
                        <h3>{postData.relatedPosts[1].title}</h3>
                        <p></p>
                      </div>
                    </a>
                    <br />
                    <a href={`/post/${postData.relatedPosts[2].slug}`} className='a-not-decorated'>
                      <div className='post-correlated'>
                        <img src={`https://api.mylondoncorner.com/${postData.relatedPosts[2].image_path}`} />
                        <h3>{postData.relatedPosts[2].title}</h3>
                        <p></p>
                      </div>
                    </a>
                    <br />
                    <br />
                  </div>
                </div>

                <div className='post-right-column'>
                  <a href="https://www.gttickets.com/it/londra/london-warner-bros-studio-tour-london-dietro-le-quinte-di-harry-potter-viaggio-di-andata-ritorno?r=mylondoncorner.gttickets.com" className='a-not-decorated'>
                    <img src="https://api.mylondoncorner.com/images/uploads/1678613445939--harry-potter-studios.jpg" style={{ borderRadius: '10px' }}  alt='Warner Bros. Studio Tour con trasporto in Bus A/R' />
                    <br />
                    <p><b>Warner Bros. Studio Tour con trasporto in Bus A/R</b></p>
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FaStar style={{ color: 'gold' }} />
                      <span style={{marginLeft: '5px' }}>4.6</span>
                      <p style={{ marginLeft: 'auto' }}>da <b>£105</b></p>
                    </div>
                    <br />
                  </a>

                  <br />

                  <hr />

                  <br /><br />

                  <a href={`/tour/tour-a-piedi-della-londra-iconica`} className='a-not-decorated'>
                    <img src="https://api.mylondoncorner.com/images/uploads/1723321874297--a.jpg" style={{ borderRadius: '10px' }} alt='Tour a piedi della Londra Iconica' />
                    <br />
                    <p><b>Tour a piedi della Londra Iconica</b></p>
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FaStar style={{ color: 'gold' }} />
                      <span style={{marginLeft: '5px' }}>5.0</span>
                      <p style={{ marginLeft: 'auto' }}>da <b>£15</b></p>
                    </div>
                    <br />
                  </a>

                  <br />

                  <hr />

                  <br /><br />

                  <a href={`/tour/tour-a-piedi-di-harry-potter-a-londra`} className='a-not-decorated'>
                    <img src="https://api.mylondoncorner.com/images/uploads/1724746135622--a.jpg" style={{ borderRadius: '10px' }} alt='Tour a piedi di Harry Potter a Londra' />
                    <br />
                    <p><b>Tour a piedi di Harry Potter a Londra</b></p>
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FaStar style={{ color: 'gold' }} />
                      <span style={{marginLeft: '5px' }}>5.0</span>
                      <p style={{ marginLeft: 'auto' }}>da <b>£20</b></p>
                    </div>
                    <br />
                  </a>
                </div>
              </div>
            </div>
            <Footer />
            <NewsletterPopup />
          </>
        ) : (
          <NotFound />
        )
      )}
    </>
  );
}

export default Post;