import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

function AdminTodos() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [todosData, setTodosData] = useState({});
  const [loadingTodosData, setLoadingTodosData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    setLoadingTodosData(true);
    axios.get(`https://api.mylondoncorner.com/todo/all`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setTodosData(response.data);
      setLoadingTodosData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTodosData(false);
    });
  }, []);

  const handleDelete = async (todoId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this todo?");
    if (confirmDelete) {
      try {
        const response = await axios.get(`https://api.mylondoncorner.com/todo/delete/${todoId}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY
          }
        });
        // Reload todos data after successful deletion
        setLoadingTodosData(true);
        const todosResponse = await axios.get(`https://api.mylondoncorner.com/todo/all`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY
          }
        });
        setTodosData(todosResponse.data);
        setLoadingTodosData(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadingTodosData(false);
      }
    }
  };
  

  return (
    <div className="table-container">
      {loadingUserData ? (
        <div className="loading-animation"></div>
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

              {loadingTodosData ? (
                <div className="loading-animation"></div>
              ) : (
                <>
                  <br />
                  <button className='button' onClick={() => navigate('/admin/todo/new')}>New</button>
                  <br />
                  <table>
                    <tr>
                      <th>Todo Date</th>
                      <th>Todo</th>
                      <th>Delete</th>
                    </tr>
                    {todosData.todos.map((todo, index) => (
                      <tr key={index}>
                        <td>{formatDate(todo.todoDate)}</td>
                        <td>{todo.todo}</td>
                        <td><button className='button' onClick={() => handleDelete(todo._id)}>Delete</button></td>
                      </tr>
                    ))}
                  </table>                
                </>
              )}

              <Footer />
            </>
          ) : (
            navigate('/')
          )
        ) : (
          navigate('/')
        )
      )}
    </div>
  );
}

// Define a function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export default AdminTodos;