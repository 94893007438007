import React from 'react';
import '../../styles/widgets/Banner.css';

const Banner = ({ title, description, link, linkText, imgUrl }) => {

  const style = {
    backgroundImage: `url(${imgUrl})`
  };  

  return (
    <div className="banner" style={style}>
      <div className="banner-overlay">
        <div className="banner-content">
          <h1 style={{ fontSize: '30px' }}>{title}</h1>
          <p>{description}</p>
          <br />
          <a href={link}>
            <button className="button">{linkText}</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;