import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Components
import Calendar from './Calendar';

// Styles
import '../../styles/tour/TourAvailabilityCalendar.css';

export default function TourAvailabilityCalendar() {
  const [toursData, setToursData] = useState([]);
  const [loadingToursData, setLoadingToursData] = useState(true);

  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const [toursByDateData, setToursByDateData] = useState([]);
  const [loadingToursByDateData, setLoadingToursByDateData] = useState(false);

  useEffect(() => {
    setLoadingToursData(true);
    axios.get('https://api.mylondoncorner.com/tours/purchasable', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      const dates = Array.from(
        new Set(
          response.data.tours
            .flatMap(tour => tour.available_dates.split(','))
            .filter(date => date !== 'NaN/NaN/NaN' && date.trim() !== '')
        )
      ).join(',').replace(/,\s*$/, '');

      setToursData(response.data.tours);
      setAvailableDates(dates.split(','));
      setLoadingToursData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingToursData(false);
    });
  }, []);

  useEffect(() => {
    if (selectedDate) {
      setLoadingToursByDateData(true);
      axios.post('https://api.mylondoncorner.com/tours/date', { date: selectedDate }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        setToursByDateData(response.data.tours);
        setLoadingToursByDateData(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoadingToursByDateData(false);
      });
    }
  }, [selectedDate]);

  const handleDateChange = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month index starts from 0
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    setSelectedDate(formattedDate);
  };

  return (
    <>
      {loadingToursData ? (
        <div className="loading-animation"></div>
      ) : (
        <>
          <div className='tour-availability-calendar-container'>
            <h2>Seleziona una data per prenotare un Tour</h2>
            <br />
            <div className='row'>
              <div className='tour-availability-calendar-column-left'>
                <Calendar 
                  selectableDates={availableDates.map(dateString => {
                    let parts = dateString.split("/");
                    let formattedDate = `${parts[1]}/${parts[0].padStart(2, '0')}/${parts[2]}`;
                    return new Date(formattedDate);
                  })} 
                  onDateChange={handleDateChange} 
                />
              </div>
              <div className='tour-availability-calendar-column-right'>
                {loadingToursByDateData ? (
                  <div className="loading-animation"></div>
                ) : (
                  toursByDateData.map((tour, index) => (
                    <a href={`/tour/${tour.slug}`}>
                      <div 
                        className='tour-availability-calendar-card' 
                        key={index} 
                        style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}/${tour.images_paths[0]})` }} // Assuming each tour object has a backgroundImageUrl property
                      >
                        <div className="text-container">
                          <p className='title'>{tour.name}</p>
                          <p className='description'>£{tour.price_child} - £{tour.price} • {tour.duration} • Tour in italiano</p>
                        </div>
                        <div className="button-container">
                          <button className='button'>Prenota</button>
                        </div>
                      </div>
                    </a>
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
