import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Navbar from './navbar/Navbar';
import Footer from './Footer';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>404 Page Not Found | mylondoncorner</title>‍
        <meta name="description" content='La pagina che stai cercando non esiste o è stata eliminata. Esplora il nostro sito o contattaci per assistenza.' />
        <meta name="robots" content="noindex, follow" />      
        <meta property="og:title" content='404 Page Not Found | mylondoncorner' />        
        <meta property="og:description" content='La pagina che stai cercando non esiste o è stata eliminata. Esplora il nostro sito o contattaci per assistenza.' />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />
      </Helmet>

      <Navbar />

      <div style={{ textAlign: 'center', padding: '50px' }}>
        <h1>Oops! Ti sei pers*?</h1>
        <br />
        <p>La pagina che stai cercando non esiste o è stata eliminata...</p>
        <br />
        <button className='button' onClick={() => navigate('/')}>Torna alla Home</button>
        <br />
        <div style={{ marginTop: '20px' }}>
          <h3>Hai bisogno di aiuto?</h3>
          <br />
          <p>Se pensi che ci sia un errore, contattaci a <a href="mailto:info@mylondoncorner.com">info@mylondoncorner.com</a></p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default NotFound;