import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/widgets/newsletterPopup.css';

const NewsletterPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const isPopupShown = sessionStorage.getItem('isPopupShown');

    if (!isPopupShown) {
      const timer = setTimeout(() => {
        setIsOpen(true);
        sessionStorage.setItem('isPopupShown', 'true');
      }, 3000); // 3 seconds delay

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('https://api.mylondoncorner.com/newsletter/user', { email }, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      if (response.status === 200) {

      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });

    setIsOpen(false);
  };

  return (
    <div>      
      {isOpen && (
        <div className="newsletter-popup">
          <div className="newsletter-popup-inner">
            <button className="newsletter-popup-close-btn" onClick={() => setIsOpen(false)}>x</button>
            <br /><br />
            <img src='https://api.mylondoncorner.com/images/logo-black.png' />
            <br />
            <h3>Iscriviti alla nostra Newsletter</h3>
            <br />
            <h4>Non perdere le ultime notizie!</h4>
            <br />
            <form onSubmit={handleSubmit}>
              <div>
                <input 
                  type="email" 
                  id="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  placeholder='Email'
                  className='contact-message'
                  required 
                />
              </div>
              <br />
              <button className='button'>Iscriviti</button>
              <br /><br />
              <p>Condividendo il tuo indirizzo email, accetti di ricevere email di marketing e acconsenti ai nostri <a href='/legal/terms-and-conditions'>Termini e Condizioni</a> e alla nostra <a href='/legal/privacy-policy'>Politica sulla Privacy</a>.</p>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsletterPopup;
