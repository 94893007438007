import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

import '../../../styles/admin/adminElse.css';

function EditPost() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { postId } = useParams();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [categoriesData, setCategoriesData] = useState({});
  const [loadingCategoriesData, setLoadingCategoriesData] = useState({});

  const [postData, setPostData] = useState({});
  const [loadingPostData, setLoadingPostData] = useState({});

  const [title, setTitle] = useState('');
  const [visible, setVisible] = useState();
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    // Fetch Categories Data
    setLoadingCategoriesData(true);
    axios.get(`https://api.mylondoncorner.com/post/categories`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setCategoriesData(response.data);
      setLoadingCategoriesData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingCategoriesData(false);
    });

    // Fetch Post Data by slug
    setLoadingPostData(true);
    axios.get(`https://api.mylondoncorner.com/post/id/${postId}`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setPostData(response.data);
      setLoadingPostData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingPostData(false);
    });
  }, []);

  useEffect(() => {
    if(!loadingPostData)
    {
      setTitle(postData.post.title)
      setVisible(postData.post.visible.toString())
      setContent(postData.post.content)
      setTags(postData.post.tags)
      setCategories(postData.post.category.map((category) => {return category._id}))
    }
  }, [postData]);

  const handlePostSubmission = async () => {
    if(title !== '' && visible !== null && content !== '' && tags !== '' && categories.length !== 0) {
      const formData = new FormData();
      formData.append('postId', postId);
      formData.append('title', title);
      formData.append('visible', visible);
      formData.append('content', content);
      formData.append('tags', tags);
      formData.append('categories[]', categories);
      formData.append('image', image);

      axios.post('https://api.mylondoncorner.com/post/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/admin/posts')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      alert("Every field must be typed in!");
    }
  };

  return (
    <>
      {loadingUserData || loadingCategoriesData || loadingPostData ? (
        <div className="loading-animation"></div>
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

                <br /><br />

                <div className='admin-container'>

                  <h1>Edit Post</h1>

                  <br /><br />

                  <p>Visible</p>
                  <select id="visible" name="visible" onChange={(e) => setVisible(e.target.value)}>
                    <option value={visible === 'true' ? '1' : '0'} selected>{visible}</option>
                    <option value="1">true</option>
                    <option value="0">false</option>
                  </select>

                  <br /><br />

                  <p>Title</p>
                  <input type='text' id="title" name="title" onChange={(e) => setTitle(e.target.value)} value={title} />

                  <br /><br />

                  <p>Tags</p>
                  <input type='text' id="tags" name="tags" onChange={(e) => setTags(e.target.value)} value={tags} />

                  <br /><br />

                  <p>Content (HTML)</p>
                  <textarea id="Content" rows={15} name="Content" onChange={(e) => setContent(e.target.value)} value={content} />

                  <br /><br />

                  <p>Categories</p>
                  <select
                    id="categories"
                    name="categories"
                    multiple
                    onChange={(e) => {
                      const selectedCategoryIds = Array.from(e.target.selectedOptions, option => option.value);
                      setCategories(selectedCategoryIds);
                    }}
                  >
                    {postData.post.category.map(category => (
                      <option key={category._id} value={category._id} selected>
                        {category.name}
                      </option>
                    ))}
                    {categoriesData.categories.map(category => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>

                  <br /><br />

                  <p>Cover image (1200x681)px</p>
                  <input type="file" name="image" onChange={(e) => setImage(e.target.files[0])} />

                  <br /><br /><br />

                  <button className='button' onClick={handlePostSubmission}>Submit</button>

                </div>

              <Footer />
            </>
          ) : (
            navigate('/')
          )
        ) : (
          navigate('/')
        )
      )}
    </>
  );
}

export default EditPost;