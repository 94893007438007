import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

import '../../styles/components/SliderTrips.css';

export default function App({ images }) {
  const navigate = useNavigate();

  return (
    <>
      <Swiper
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2.5,
            spaceBetween: 30,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
        }}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
      >
        {images
          .filter(item => {
            const departureDate = new Date(item.departure_date);
            return (item.status === "open" || item.status === "sold out") && 
                  item.visible === true && 
                  departureDate > new Date(); // Filter out past dates
          })
          .map(item => (
            <SwiperSlide key={item._id} onClick={(e) => { e.preventDefault(); navigate(`/trip/${item.slug}`); }}>
              <img src={'https://api.mylondoncorner.com/' + item.images_paths[0]} style={{ cursor: 'pointer', width: '100%', borderRadius: '10px', border: 'solid 1px #ccc' }} alt="Image" />
              <p className='slider-trips-p'>{formatDate(item.departure_date)} - {formatDate(item.return_date)}</p>
              <h4 className='slider-trips-h4'>{item.name}</h4>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2%', fontSize: '15px' }}>
                <p className='slider-trips-p'>{'Da ' + item.price + '€ / persona'}</p>
                <p className='slider-trips-p' style={{ textAlign: 'right', color: item.status === 'open' ? 'green' : 'red', fontWeight: 'bold' }}>{item.status === 'open' ? 'APERTO' : item.status === 'cancelled' ? 'Cancellato' : 'Sold Out'}</p>
              </div>              
              <br/><br/>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </>
  );
}

// Function to format date in UK format (dd/mm/yyyy)
function formatDate(dateStr) {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDay = day < 10 ? '0' + day : day;
  const formattedMonth = month < 10 ? '0' + month : month;
  return `${formattedDay}/${formattedMonth}/${year}`;
}