import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Footer from '../../components/Footer';
import Navbar from '../../components/navbar/Navbar';

import '../../styles/contact.css';

function AdminSendEmail() {
  const navigate = useNavigate();

  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [html, setHtml] = useState("Informazioni");

  const handleSubmission = async () => {
    if(to !== '' && subject !== '' && html !== '' && to.includes('@')) {
      axios.post('https://api.mylondoncorner.com/admin/send-email', {
        to,
        subject,
        html
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          alert('Email sent.')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      if(!to.includes('@')) {
        alert({message: "E-mail not valid!", type: "error"});
      } else {
        alert({message: "All fields must be filled!", type: "error"});
      }
    }
  };

  return (
    <>
      <Navbar />
        <br /><br />
        <div className='contact-container'>
          <h1>Send an HTML e-mail</h1>
          <br /><br />
          <input type='email' id="to" name="to" onChange={(e) => setTo(e.target.value)} className='contact-message' placeholder='Insert receiver e-mail' />
          <br /><br />
          <input type='text' id="subject" name="subject" onChange={(e) => setSubject(e.target.value)} className='contact-message' placeholder='Insert Subject' />
          <br /><br />
          <textarea id="html" name="html" onChange={(e) => setHtml(e.target.value)} rows={20} className='contact-message' placeholder='Html' />
          <br /><br />
          <button className='button' onClick={handleSubmission}>Invia</button>
        </div>
      <Footer />
    </>
  );
}

export default AdminSendEmail;