import React from 'react';

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/Footer';

function TermsAndConditions() {
  return (
    <>
      <Navbar />

      <br /><br />

      <div className='home-container' style={{ textAlign: 'left' }}>

        <h1 style={{ textAlign: 'center' }}>Termini e Condizioni</h1>

        <br /><br /><br />

        <p>
            Benvenuti su MyLondonCorner, un servizio di tour operator registrato in Inghilterra con il numero di registrazione 14771046 
            e con sede legale presso Suite 205 Balfour House, 741 High Road, London, United Kingdom, N12 0BP. 
            Prima di procedere con la prenotazione dei nostri servizi, vi preghiamo di leggere attentamente i seguenti Termini e Condizioni. 
            L'utilizzo dei nostri servizi implica l'accettazione automatica di questi termini.
        </p>

        <br /><br />

        <h3>1. Prenotazioni e Pagamenti</h3>

        <br />

        <p>1.1. Tutte le prenotazioni devono essere effettuate attraverso il nostro sito web o tramite i nostri canali di contatto ufficiali.</p>

        <br />

        <p>
            1.2. I pagamenti possono essere effettuati tramite le modalità indicate sul nostro sito web. 
            Eventuali variazioni o rimborsi saranno soggetti alle nostre politiche di cancellazione.
        </p>

        <br /><br />

        <h3>2. Politiche di Cancellazione e Modifica</h3>

        <br />

        <p>
            2.1. Le richieste di cancellazione o modifica devono essere comunicate per iscritto entro i termini indicati nella descrizione del servizio prenotato. 
            Le politiche di cancellazione variano a seconda del tipo di servizio e della stagione.
        </p>

        <br />

        <p>2.2. Per i tour operati da MyLondonCorner, è prevista una fee di cancellazione del 2.5%.</p>

        <br />

        <p>2.3. Alcuni servizi potrebbero essere non rimborsabili in caso di cancellazione tardiva o mancata presentazione.</p>

        <br /><br />

        <h3>3. Responsabilità</h3>

        <br />

        <p>
            3.1. MyLondonCorner non è responsabile per ritardi, cancellazioni, danni, perdite o costi derivanti da cause di forza maggiore, 
            inclusi eventi naturali, scioperi, o azioni di terze parti.
        </p>

        <br />

        <p>
            3.2. Gli ospiti sono responsabili del proprio comportamento durante i tour e devono rispettare le leggi e le regole locali. 
            MyLondonCorner si riserva il diritto di escludere chiunque comporti un pericolo per se stesso, gli altri partecipanti o il personale.
        </p>

        <br /><br />

        <h3>4. Variazioni dei Servizi</h3>

        <br />

        <p>
          4.1. MyLondonCorner si riserva il diritto di apportare modifiche ai servizi offerti, 
          inclusi itinerari, hotel e attività, a sua discrezione. 
          I partecipanti saranno informati tempestivamente in caso di variazioni significative.
        </p>

        <br /><br />

        <h3>5. Proprietà Intellettuale</h3>

        <br />

        <p>
          5.1. Tutti i contenuti presenti sul sito web e sui materiali forniti da MyLondonCorner sono protetti da diritti d'autore e altre leggi sulla proprietà intellettuale. 
          L'uso non autorizzato di tali contenuti è vietato.
        </p>

        <br /><br />

        <h3>6. Privacy</h3>

        <br />

        <p>
          6.1. MyLondonCorner tratta i dati personali dei clienti conformemente alla nostra Politica sulla Privacy. 
          L'utente accetta le condizioni della nostra Politica sulla Privacy utilizzando i nostri servizi.
        </p>
          
        <br /><br />

        <h3>7. Legge Applicabile</h3>

        <br />

        <p>7.1. Questi Termini e Condizioni sono regolati dalla legge inglese. In caso di controversie, le parti accettano la giurisdizione esclusiva dei tribunali inglesi.</p>

        <br /><br />

        <h3>8. Contatti</h3>

        <br />

        <p>8.1. In caso di domande, reclami o richieste, potete contattarci ai nostri recapiti ufficiali indicati sul sito web.</p>

        <br />

        <p>
          Accedendo e utilizzando i servizi di MyLondonCorner, l'utente accetta tutti i termini e le condizioni sopra descritti. 
          Si prega di leggere attentamente e di contattarci per ulteriori chiarimenti, se necessario.
        </p>

      </div>
      
      <Footer />
    </>
  )
}

export default TermsAndConditions;