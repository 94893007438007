import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

import '../../../styles/admin/adminElse.css';

function NewTourBooking() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [tourData, setTourData] = useState({});
  const [loadingTourData, setLoadingTourData] = useState({});

  const [tourGuidesData, setTourGuidesData] = useState({});
  const [loadingTourGuides, setLoadingTourGuides] = useState({});

  const [status, setStatus] = useState('pending');
  const [tour, setTour] = useState('');
  const [tourGuide, setTourGuide] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [adult, setAdult] = useState();
  const [adultTourPrice, setAdultTourPrice] = useState();
  const [children, setChildren] = useState();
  const [childrenTourPrice, setChildrenTourPrice] = useState();
  const [tourDate, setTourDate] = useState();
  const [duration, setDuration] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    // Fetch Tour Data by category
    setLoadingTourData(true);
    axios.get(`https://api.mylondoncorner.com/tours/category/id/6419fdffef79c8a1049172d0`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setTourData(response.data);
      setLoadingTourData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTourData(false);
    });

    // Fetch Tour Guides
    setLoadingTourGuides(true);
    axios.get(`https://api.mylondoncorner.com/tourguides`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setTourGuidesData(response.data);
      setLoadingTourGuides(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTourGuides(false);
    });
  }, []);

  const handleTourBookingSubmission = async () => {
    if(status !== '' && tour !== '' && tourGuide !== '' && email !== '' && adult != null && adultTourPrice != null && children != null && childrenTourPrice != null && tourDate !== null && email.includes('@')) {
      axios.post('https://api.mylondoncorner.com/tourbooking/save', {
        status: status,
        tour: tour,
        tourGuide: tourGuide,
        email: email,
        phone: phone,
        adult: adult,
        adultTourPrice: adultTourPrice,
        children: children,
        childrenTourPrice: childrenTourPrice,
        tourDate: tourDate,
        duration: duration,
        notes: notes
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/admin/tour-bookings')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      if(!email.includes('@')) {
        alert("E-mail non valida!");
      } else {
        alert("Every field must be typed in!");
      }
    }
  };

  return (
    <>
      {loadingUserData || loadingTourData || loadingTourGuides ? (
        <div className="loading-animation"></div>
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

                <br /><br />

                <div className='admin-container'>

                  <h1>New Tour Booking</h1>

                  <br /><br />

                  <p>Status</p>
                  <select id="status" name="status" onChange={(e) => setStatus(e.target.value)}>
                    <option value="pending">pending</option>
                    <option value="confirmed">confirmed</option>
                    <option value="cancelled">cancelled</option>
                    <option value="completed">completed</option>
                  </select>

                  <br /><br />

                  <p>Tour</p>
                  <select id="tour" name="tour" onChange={(e) => setTour(e.target.value)}>
                    <option value="" disabled selected>Select a Tour</option>
                    {tourData.tours.map(tour => {
                      return(
                        <option value={tour._id}>{tour.name}</option>
                      )
                    })}
                  </select>

                  <br /><br />

                  <p>Tour Guide</p>
                  <select id="tourGuide" name="tourGuide" onChange={(e) => setTourGuide(e.target.value)}>
                    <option value="" disabled selected>Select a Tour Guide</option>
                    {tourGuidesData.tourGuides.map(guide => {
                      return(
                        <option value={guide._id}>{guide.name}</option>
                      )
                    })}
                  </select>

                  <br /><br />

                  <p>Customer E-mail</p>
                  <input type='email' id="email" name="email" onChange={(e) => setEmail(e.target.value)} placeholder='Insert customer e-mail' />

                  <br /><br />

                  <p>Customer Phone Number</p>
                  <input type='text' id="phone" name="phone" onChange={(e) => setPhone(e.target.value)} placeholder='Insert customer phone number' />

                  <br /><br />

                  <p>Number of Adult</p>
                  <input type='number' step='1' id="adult" name="adult" onChange={(e) => setAdult(e.target.value)} placeholder='Insert number of adult' />

                  <br /><br />

                  <p>Adult Tour Price</p>
                  <input type='number' step='0.01' id="adultTourPrice" name="adultTourPrice" onChange={(e) => setAdultTourPrice(e.target.value)} placeholder='Insert adult tour price' />

                  <br /><br />

                  <p>Number of Children</p>
                  <input type='number' step='1' id="children" name="children" onChange={(e) => setChildren(e.target.value)} placeholder='Insert number of children' />

                  <br /><br />

                  <p>Children Tour Price</p>
                  <input type='number' step='0.01' id="childrenTourPrice" name="childrenTourPrice" onChange={(e) => setChildrenTourPrice(e.target.value)} placeholder='Insert children tour price' />

                  <br /><br />

                  <p>Tour Duration</p>
                  <input type='number' step='0.01' id="duration" name="duration" onChange={(e) => setDuration(e.target.value)} placeholder='Insert tour duration' />

                  <br /><br />

                  <p>Tour Date</p>
                  <input type='date' id="tourDate" name="tourDate" onChange={(e) => setTourDate(e.target.value)} />

                  <br /><br />

                  <p>Notes</p>
                  <input type='text' id="notes" name="notes" onChange={(e) => setNotes(e.target.value)} placeholder='Insert notes' />

                  <br /><br /><br />

                  <button className='button' onClick={handleTourBookingSubmission}>Add</button>

                </div>

              <Footer />
            </>
          ) : (
            navigate('/')
          )
        ) : (
          navigate('/')
        )
      )}
    </>
  );
}

export default NewTourBooking;