import React from 'react';
import '../../styles/widgets/termsCheckbox.css';

const TermsCheckbox = ({ type, isChecked, toggleCheckbox }) => {
  const toggleCheckboxLocal = () => {
    toggleCheckbox(!isChecked);
  };

  return (
    <div className="consent-container">
      <label className="custom-checkbox">
        <input type="checkbox" checked={isChecked} onChange={toggleCheckboxLocal} />
        <span className="checkmark"></span>
      </label>
      <label htmlFor="consent-checkbox" className="consent-text">
        {type === 'privacy' && <span>Ho letto e accetto la <a href="/legal/privacy-policy">privacy policy</a>.</span>}
        {type === 'termsandconditions' && <span>Ho letto e accetto i <a href="/legal/terms-and-conditions">termini e condizioni</a>.</span>}
      </label>
    </div>
  );
};

export default TermsCheckbox;
