import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// Icons
import { airplane, call, card } from 'ionicons/icons'; // Importing icons from Ionicons
import { IonIcon } from '@ionic/react'; // Importing IonIcon component from @ionic/react

// Navigation
import { useNavigate } from 'react-router-dom';

// Components
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';
import FeedbackPopup from '../components/widgets/FeedbackPopup';

// Styles
import '../styles/otherProducts.css';
import '../styles/tables.css';
import '../styles/inputs.css';

function PrivateGuides() {
  const navigate = useNavigate();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [days, setDays] = useState(1);
  const [meetingPoint, setMeetingPoint] = useState('');
  const [preferences, setPreferences] = useState('');
  
  // State to hold the selected durations for each day
  const [durations, setDurations] = useState(Array(days).fill(4)); // Default to 4 hours

  // State to hold the departure times for each day
  const [departureTimes, setDepartureTimes] = useState(Array(days).fill('10:00')); // Default to 10:00

  useEffect(() => {
    // Calculate the default pickup date (2 days from now)
    const now = new Date();
    // Andata
    now.setDate(now.getDate() + 7);
    var formattedDate = now.toISOString().split('T')[0]; // Format date to YYYY-MM-DD
    setStartDate(formattedDate); // Set default pickup date
    setEndDate(formattedDate); // Set default pickup date
  }, []); // Empty dependency array to run only on mount

  // Function to calculate the number of days between two dates
  const calculateDays = (arrival, departure) => {
    const arrivalDate = new Date(arrival);
    const departureDate = new Date(departure);
    const differenceInTime = departureDate.getTime() - arrivalDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1; // +1 to include both days
    setDays(differenceInDays);
    setDurations(Array(differenceInDays).fill(4)); // Reset durations on date change
  };

  // Call the calculateDays function whenever the dates change
  useEffect(() => {
    if (startDate && endDate) {
      calculateDays(startDate, endDate);
    }
  }, [startDate, endDate]);

  const handleDurationChange = (dayIndex, value) => {
    const newDurations = [...durations];
    newDurations[dayIndex] = value; // Update the selected duration for the specific day
    setDurations(newDurations);
  };

  // New function to handle departure time change
  const handleDepartureTimeChange = (dayIndex, value) => {
    const newDepartureTimes = [...departureTimes];
    newDepartureTimes[dayIndex] = value; // Update the selected departure time for the specific day
    setDepartureTimes(newDepartureTimes);
  };

  const handleItinerarySubmission = async () => {
    if (name && email && startDate && endDate && meetingPoint && email.includes('@')) {
      const formattedDurations = durations.map((duration, index) => {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + index);
        const formattedDate = currentDate.toLocaleDateString('it-IT');
        const departureTime = departureTimes[index]; // Get the departure time for the current day
        return `${formattedDate} | ${departureTime} | ${duration === 0 ? 'No Tour' : duration + ' ore'}`;
      });

      let message = `
        <div>
          <ul>
            <li><strong>Start Date:</strong> ${startDate}</li>
            <li><strong>End Date:</strong> ${endDate}</li>
            <li><strong>Durations and Departure Times:</strong></li>
            <ul>
              ${formattedDurations.map(duration => `<li>${duration}</li>`).join('')}
            </ul>
            <li><strong>Meeting Point:</strong> ${meetingPoint}</li>
            <li><strong>Preferences:</strong> ${preferences}</li>
          </ul>
        </div>
      `;

      axios.post('https://api.mylondoncorner.com/contact', {
        subject: 'Richiesta Tour Privato',
        name,
        email,
        message
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if (response.status === 200) {
          navigate('/contact/received');
        }
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
    } else {
      setFeedbackMessage(email.includes('@') ? "Tutti i campi devono essere compilati!" : "E-mail non valida!");
      setIsPopupOpen(true);
    }
  };

  // Function to format a date to a readable string
  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty date string
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('it-IT', options);
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Guida Privata</title>
        <meta name="description" content="Tour su misura in base alle tue preferenze" />
      </Helmet>

      <Navbar />

      <br /><br />

      <div className='post-container'>
        <h2>Richiedi una guida privata</h2>
        <br />
        <p>Tour su misura in base alle tue preferenze</p>
        <br /><br />

        <div className='row'>
          <div className='other-products-column-input-3'>
            <p>Inserisci Data di inizio dei Tour</p>
            <input
              type='date'
              value={startDate}
              onChange={(e) => {
                const newStartDate = e.target.value;
                setStartDate(newStartDate);
                
                if (new Date(newStartDate) > new Date(endDate)) {
                  setEndDate(newStartDate); // Set end date to the same as start date if it's earlier
                }
              }}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci Data di fine dei Tour</p>
            <input
              type='date'
              value={endDate}
              onChange={(e) => {
                const newEndDate = e.target.value;
                setEndDate(newEndDate);
                
                if (new Date(newEndDate) < new Date(startDate)) {
                  setStartDate(newEndDate); // Set start date to the same as end date if it's later
                }
              }}
            />
            <br /><br />
          </div>
        </div>

        <div className='rows-container'>
          <br /><hr /><br /><br />
          {Array.from({ length: Math.ceil(days / 3) }, (_, rowIndex) => (
            <div key={rowIndex} className='row'>
              {Array.from({ length: 3 }, (_, columnIndex) => {
                const dayIndex = rowIndex * 3 + columnIndex;
                if (dayIndex >= days) return null;
                const currentDate = new Date(startDate);
                currentDate.setDate(currentDate.getDate() + dayIndex);

                if (isNaN(currentDate.getTime())) {
                  console.error('Invalid date:', currentDate);
                  return null;
                }

                return (
                  <div key={dayIndex} className='other-products-column-input-3'>
                    <p><b>{formatDate(currentDate.toISOString().split('T')[0])}</b></p>
                    <br />
                    <label>Durata del Tour:</label>
                    <select
                        value={durations[dayIndex]}
                        onChange={(e) => handleDurationChange(dayIndex, parseInt(e.target.value))}
                        style={{ padding: '12px', border: '3px solid rgb(52, 196, 39)' }}
                    >
                        <option value={4}>4 ore</option>
                        <option value={7}>7 ore</option>
                        <option value={0}>No Tour</option>
                    </select>
                    <br /><br />
                    <label htmlFor={`departure-time-${dayIndex}`}>Ora di Partenza:</label>
                    <input
                      type='time'
                      id={`departure-time-${dayIndex}`}
                      value={departureTimes[dayIndex]}
                      onChange={(e) => handleDepartureTimeChange(dayIndex, e.target.value)}
                      style={{ padding: '12px', border: '3px solid rgb(52, 196, 39)' }}
                    />
                    <br /><br />
                  </div>
                );
              })}
            </div>
          ))}
          <br /><hr /><br /><br />
        </div>

        <div className='row'>
          <div className='other-products-column-input-3'>
            <p>Inserisci il tuo Nome</p>
            <input
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci la tua E-mail</p>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br /><br />
          </div>
          <div className='other-products-column-input-3'>
            <p>Inserisci il punto di incontro che preferisci</p>
            <input
              type='text'
              value={meetingPoint}
              placeholder='(Alloggio, Attrazione, ...)'
              onChange={(e) => setMeetingPoint(e.target.value)}
            />
            <br /><br />
          </div>
        </div>

        <div className='row'>
          <div className='other-products-column-input-1'>
            <p>Cosa vorresti vedere?</p>
            <textarea
              value={preferences}
              placeholder='Cosa non puoi assolutamente perdere? Qualche attrazione?'
              rows={5}
              onChange={(e) => setPreferences(e.target.value)}
            >
            </textarea>
            <br /><br />
          </div>
        </div>

        <div className='row'>
          <button
            className='button'
            onClick={handleItinerarySubmission}
          >
            Richiedi
          </button>
        </div>

      <br /><br /><br />

        <div className='other-products-column-input-3-icons-row'>
          <div className='other-products-column-input-3-icons'>
            <IonIcon icon={airplane} />
            <div className='other-products-column-input-3-icons-text'>
              <b>Tour Personalizzato</b><br />
              Ogni tour è creato su misura, considerando le tue preferenze di viaggio.
            </div>
          </div>
          <div className='other-products-column-input-3-icons'>
            <IonIcon icon={card} />
            <div className='other-products-column-input-3-icons-text'>
              <b>Esperienze autentiche</b><br />
              Scopri attrazioni e luoghi locali selezionati per vivere un'esperienza autentica.
            </div>
          </div>
          <div className='other-products-column-input-3-icons'>
            <IonIcon icon={call} />
            <div className='other-products-column-input-3-icons-text'>
              <b>Flessibilità</b><br />
              Modifica il tuo itinerario in qualsiasi momento, con supporto dedicato in italiano.
            </div>
          </div>
        </div>

        <br /><br /><br />

        <h2>Cosa è incluso?</h2>

        <br />

        <p>• Realizzazione di un Tour dettagliato a piedi guidato da un esperto/a locale che parla italiano.</p><br />
        <p>• Prenotazioni per attrazioni.</p><br />
        <p>• Consigli su ristoranti, fast food e pub.</p><br />
        <p>• Informazioni utili per il tuo viaggio.</p><br />
        <p>• Supporto in italiano prima e durante il tuo soggiorno a Londra via E-mail.</p>

        <br /><br /><br />

        <h2>Informazioni importanti</h2>

        <br />

        <p>• Il massimo di partecipanti consentito per questi tour ai corrispettivi prezzi è di 10 persone.</p><br />
        <p>• Per gruppi più numerosi di 10 persone, vi preghiamo di indicarlo nel modulo di richiesta.</p><br />
        <p>• Non è incluso il biglietto della metropolitana o bus (siate pronti con un oyster card o carta contactless).</p><br />
        <p>• È possibile cancellare la prenotazione fino a 72 ore prima dell'inizio del tour per ottenere un rimborso completo.</p><br />
        <p>• La guida sarà in italiano, garantendo una migliore comprensione e fruizione dell'esperienza.</p>

        <br /><br />

        <table>
            <tr>
              <th>Durata</th>
              <th>Costo</th>
            </tr>
            <tr>
              <td>4 ore</td>
              <td>£160</td>
            </tr>
            <tr>
              <td>7 ore</td>
              <td>£210</td>
            </tr>
            <tr>
              <td>Piú ore</td>
              <td>£--</td>
            </tr>
          </table>

        <br /><br /><br />
      </div>

      <Footer />
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={() => setIsPopupOpen(false)}
        confirmButtonText="OK"
      />
    </>
  );
}

export default PrivateGuides;