import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

// Styles
import '../../styles/tour/tourCalendar.css';

const MyCalendar = ({ selectableDates, onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  // Function to determine if a date is selectable
  const tileClassName = ({ date }) => {
    return selectableDates.some(d => isSameDay(d, date)) ? 'available-date' : '';
  };

  const handleDateChange = (date) => {
    if (selectableDates.some(d => isSameDay(d, date))) {
      setSelectedDate(date);
      onDateChange(date); // Call the callback prop with the new date
    }
  };

  // Function to determine if a date is disabled
  const tileDisabled = ({ date }) => {
    return !selectableDates.some(d => isSameDay(d, date));
  };

  // Helper function to compare if two dates are the same day
  const isSameDay = (dateA, dateB) => {
    return (
      dateA.getDate() === dateB.getDate() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getFullYear() === dateB.getFullYear()
    );
  };

  // Custom navigation label that does nothing to prevent year navigation
  const navigationLabel = ({ date, view, label }) => {
    return view === 'month' ? <span>{label}</span> : null;
  };

  // Prevent changing the active start date when attempting to navigate
  const onActiveStartDateChange = ({ activeStartDate, view }) => {
    // Do nothing to effectively disable changing years through the UI
  };

  return (
    <>
      <div className="calendar-wrapper">
        <Calendar
          locale="it-IT"
          className="custom-calendar"
          value={selectedDate}
          onChange={handleDateChange}
          tileClassName={tileClassName} // Apply custom class to date tiles
          tileDisabled={tileDisabled} // Disable dates that are not selectable
        />
      </div>
    </>
  );
};

export default MyCalendar;
