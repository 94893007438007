import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';

import Home from './views/Home';

import Basket from './views/Basket';
import PaymentSuccessful from './views/PaymentSuccessful';
import PaymentFailed from './views/PaymentFailed';

import Account from './views/account/Account';
import Signup from './views/account/Signup';
import Login from './views/account/Login';
import ResetPassword from './views/account/ResetPassword';

import Contact from './views/Contact';

import PersonalisedItineraries from './views/PersonalisedItineraries';
import PrivateGuides from './views/PrivateGuides';
import Taxi from './views/Taxi';

import Blog from './views/blog/Blog';
import Post from './views/blog/Post';

import Tour from './views/tour/Tour';
import Tours from './views/tour/Tours';
import OurTours from './views/tour/OurTours';
import TourVoucher from './views/tour/TourVoucher';
import ToursCategory from './views/tour/ToursCategory';

import MessageReceived from './views/MessageReceived';

import Trip from './views/trip/Trip';
import Trips from './views/trip/Trips';
import TripBook from './views/trip/TripBook';

import PrivacyPolicy from './views/legal/PrivacyPolicy';
import TermsAndConditions from './views/legal/TermsAndConditions';
import TermsAndConditionsHp from './views/legal/TermsAndConditionsHp';

import Search from './views/searchResult';

import AdminPanel from './views/admin/AdminPanel';
import AdminUsers from './views/admin/AdminUsers';
import AdminTourBookings from './views/admin/tourbooking/AdminTourBookings';
import AdminTourBookingNew from './views/admin/tourbooking/AdminTourBookingNew';
import AdminTourBookingEdit from './views/admin/tourbooking/AdminTourBookingEdit';
import AdminPosts from './views/admin/post/AdminPosts';
import AdminPostNew from './views/admin/post/AdminPostNew';
import AdminPostEdit from './views/admin/post/AdminPostEdit';
import AdminTours from './views/admin/tour/AdminTours';
import AdminTourNew from './views/admin/tour/AdminTourNew';
import AdminTourEdit from './views/admin/tour/AdminTourEdit';
import AdminTrips from './views/admin/trip/AdminTrips';
import AdminTripNew from './views/admin/trip/AdminTripNew';
import AdminMessages from './views/admin/AdminMessages';
import AdminTourBasketItems from './views/admin/AdminTourBasketItems';
import AdminSearches from './views/admin/AdminSearches';
import AdminTodos from './views/admin/todo/AdminTodos';
import AdminTodoNew from './views/admin/todo/AdminTodoNew';
import AdminNewsletterUsers from './views/admin/newsletter/AdminNewsletterUsers';
import AdminNewsletterSend from './views/admin/newsletter/Send';
import AdminSendEmail from './views/admin/AdminSendEmail';

import HpCounter from './views/HpCounter';

import Sitemap from './views/Sitemap';

import ScrollToTop from './components/ScrollToTop';

import NotFound from './components/NotFound'; // Import your custom 404 component

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/legal/terms-and-conditions-hp" element={<TermsAndConditionsHp />} />

          <Route path="/basket" element={<Basket />} />
          <Route path="/basket/checkout/payment-successful/:ids" element={<PaymentSuccessful />} />
          <Route path="/basket/checkout/payment-failed" element={<PaymentFailed />} />

          <Route path="/signup" element={<Signup />} />

          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/received" element={<MessageReceived />} />

          <Route path="/account" element={<Account />} />

          <Route path="/personalised-itineraries" element={<PersonalisedItineraries />} />
          <Route path="/private-guides" element={<PrivateGuides />} />
          <Route path="/taxi" element={<Taxi />} />

          <Route path="/post/:slug" element={<Post />} />
          <Route path="/blog" element={<Blog />} />

          <Route path="/tour/:slug" element={<Tour />} />
          <Route path="/tour/voucher/:id" element={<TourVoucher />} />
          <Route path="/tours" element={<Tours />} />
          <Route path="/tour-di-londra" element={<OurTours />} />
          <Route path="/tours/category/:categoryName" element={<ToursCategory />} />

          <Route path="/trips" element={<Trips />} />
          <Route path="/trip/:slug" element={<Trip />} />
          <Route path="/trip/:slug/book" element={<TripBook />} />

          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/admin" element={<AdminPanel />} />

          <Route path="/admin/users" element={<AdminUsers />} />

          <Route path="/admin/tour-bookings" element={<AdminTourBookings />} />
          <Route path="/admin/tour-booking/new" element={<AdminTourBookingNew />} />
          <Route path="/admin/tour-booking/edit/:tourBookingId" element={<AdminTourBookingEdit />} />

          <Route path="/admin/posts" element={<AdminPosts />} />
          <Route path="/admin/post/new" element={<AdminPostNew />} />
          <Route path="/admin/post/edit/:postId" element={<AdminPostEdit />} />

          <Route path="/admin/tours" element={<AdminTours />} />
          <Route path="/admin/tour/new" element={<AdminTourNew />} />
          <Route path="/admin/tour/edit/:tourId" element={<AdminTourEdit />} />
          <Route path="/admin/tour-basket-items" element={<AdminTourBasketItems />} />

          <Route path="/admin/trips" element={<AdminTrips />} />
          <Route path="/admin/trip/new" element={<AdminTripNew />} />

          <Route path="/admin/messages" element={<AdminMessages />} />

          <Route path="/admin/searches" element={<AdminSearches />} />

          <Route path="/admin/todos" element={<AdminTodos />} />
          <Route path="/admin/todo/new" element={<AdminTodoNew />} />

          <Route path="/admin/newsletter/users" element={<AdminNewsletterUsers />} />
          <Route path="/admin/newsletter/send" element={<AdminNewsletterSend />} />

          <Route path="/admin/send-email" element={<AdminSendEmail />} />

          <Route path="/search/:string" element={<Search />} />

          <Route path="/hp-counter" element={<HpCounter />} />

          <Route path="/sitemap" element={<Sitemap />} />

          <Route path='*' element={<NotFound />}/>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
