import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../components/Footer";
import Navbar from "../../components/navbar/Navbar";

function AdminUsers() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [usersData, setUsersData] = useState({});
  const [loadingUsersData, setLoadingUsersData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    setLoadingUsersData(true);
    axios.get(`https://api.mylondoncorner.com/user/all`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setUsersData(response.data);
      setLoadingUsersData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingUsersData(false);
    });
  }, []);

  return (
    <div className="table-container">
      {loadingUserData ? (
        <div className="loading-animation"></div>
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

              {loadingUsersData ? (
                <div className="loading-animation"></div>
              ) : (
                <>
                  <br />
                  <table>
                    <tr>
                      <th>E-mail</th>
                      <th>Type</th>
                      <th>First Access</th>
                      <th>Delete</th>
                    </tr>
                    {usersData.users.map((user, index) => (
                      
                        <tr key={index}>
                            <td>{user.email}</td>
                            <td>{user.address ? 'Email user' : user.name ? 'Google User' : 'Guest'}</td>
                            <td>{formatDate(user.createdAt)}</td>
                            <td><button className='button'>Delete</button></td>
                        </tr>
                    ))}
                  </table>                
                </>
              )}

              <Footer />
            </>
          ) : (
            navigate('/')
          )
        ) : (
          navigate('/')
        )
      )}
    </div>
  );
}

// Define a function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export default AdminUsers;