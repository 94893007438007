import React from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';

import { closeCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

function PaymentFailed() {

  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <br /><br /><br />
      <IonIcon icon={closeCircleOutline} style={{ display: 'block', margin: '0 auto', color: 'red', fontSize: '50px' }} />
      <h2 style={{ textAlign: 'center' }}>Pagamento fallito</h2>
      <br /><br />
      <button className='button' onClick={() => navigate('/basket')}>Ritorna al carrello</button>
      <Footer />
    </>
  );
}

export default PaymentFailed;